// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth"; // Importér authentication
import { getFirestore } from "firebase/firestore";
import  {getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCp0sKkDAuGOlQrLqsZACrEpR1K0m93fss",
  authDomain: "genbrugsspillet.firebaseapp.com",
  projectId: "genbrugsspillet",
  storageBucket: "genbrugsspillet.appspot.com",
  messagingSenderId: "95084421124",
  appId: "1:95084421124:web:7b864b2c8f62f1b1bc5551",
  measurementId: "G-C1L22GGB5H"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app); // Initialiser Firebase Authentication
const provider = new GoogleAuthProvider(); // Opret en GoogleAuthProvider
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, provider, db, storage };
