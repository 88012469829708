import React from 'react';
import { imageUrls } from './ImageUrls'; // Import imageUrls

const ImageSelector = ({ onSelectImage, selectedImageId }) => {
  return (
    <div className="image-selector-container">
      <div className="available-images">
        {imageUrls.map((image) => (
          <img
            key={image.id}
            src={image.src}
            alt={`${image.id}`}
            onClick={() => onSelectImage(image.id)}
            className={`image-option ${image.id === selectedImageId ? 'selected' : ''}`}
          />
        ))}
      </div>
    </div>
  );
};


export default ImageSelector;
