import React, { useEffect, useState } from 'react';
import { collection, onSnapshot, doc, updateDoc } from "firebase/firestore";
import { db } from '../FirebaseConfig';
import '../CMSComponents/css/ComponentStyling.css';
import { setGlobalUpdated } from './Functions/GlobalUpdated';

export default function StaticDataTable() {
    const [staticData, setStaticData] = useState({});
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [expandedDocument, setExpandedDocument] = useState(null);
    const [showAll, setShowAll] = useState(true);
    const [editingDocId, setEditingDocId] = useState(null); // Track currently editing document
    const [originalData, setOriginalData] = useState({});
    const [hasChanges, setHasChanges] = useState(false);

    useEffect(() => {
        // Load data from localStorage with error handling
        try {
            const localData = localStorage.getItem('staticData');
            if (localData) {
                setStaticData(JSON.parse(localData));
                setIsDataLoaded(true);
            }
        } catch (error) {
            console.error('Fejl under indlæsning fra localdata:', error);
        }

        const unsubscribe = onSnapshot(collection(db, 'StaticData'), (snapshot) => {
            const updatedStaticData = {};
            snapshot.forEach(doc => {
                updatedStaticData[doc.id] = doc.data();
            });

            // Update state and localStorage
            try {
                setStaticData(updatedStaticData);
                localStorage.setItem('staticData', JSON.stringify(updatedStaticData));
                setIsDataLoaded(true);
            } catch (error) {
                console.error('Error updating localStorage:', error);
            }
        });
        return () => unsubscribe();
    }, []);

    const handleFieldChange = (e, docId, fieldName, subFieldName = null) => {
        let { value } = e.target;

        // Convert `value` to a number if the field is expected to be numeric
        if (fieldName !== 'firstBuildItem' && !isNaN(value)) {
            value = Number(value); // Only convert if the value can be interpreted as a number
        }
        
        setStaticData(prevData => {
            const updatedData = {
                ...prevData,
                [docId]: {
                    ...prevData[docId],
                    [fieldName]: subFieldName
                        ? {
                            ...prevData[docId][fieldName],
                            [subFieldName]: value
                        }
                        : value
                }
            };

            // Compare each field to originalData to determine if any changes were made
            const originalValue = subFieldName
                ? originalData[docId]?.[fieldName]?.[subFieldName]
                : originalData[docId]?.[fieldName];

            const currentValue = subFieldName
                ? updatedData[docId][fieldName][subFieldName]
                : updatedData[docId][fieldName];

            // Check if the field has changed
            const hasFieldChanged = currentValue !== originalValue;

            // Check if any field in the document has changed
            let hasDocumentChanged = false;
            Object.keys(updatedData[docId]).forEach(key => {
                if (typeof updatedData[docId][key] === 'object') {
                    Object.keys(updatedData[docId][key]).forEach(subKey => {
                        if (updatedData[docId][key][subKey] !== originalData[docId]?.[key]?.[subKey]) {
                            hasDocumentChanged = true;
                        }
                    });
                } else {
                    if (updatedData[docId][key] !== originalData[docId]?.[key]) {
                        hasDocumentChanged = true;
                    }
                }
            });

            // Update hasChanges flag if any field in the document has changed
            setHasChanges(hasDocumentChanged);

            return updatedData;
        });
    };




    const handleSaveData = async (docId) => {
        try {
            const docRef = doc(db, 'StaticData', docId);
            await updateDoc(docRef, {
                ...staticData[docId],
                LastUpdated: new Date()
            });

            // Calls Custom function to set global last updated
            await setGlobalUpdated();

            alert("Data er blevet opdateret!");
            setEditingDocId(null); // Exit edit mode after saving
        } catch (error) {
            console.error("Fejl under opdatering:", error);
            alert("Der opstod en fejl under opdateringen.");
        }
        setHasChanges(false); // Reset changes after saving
    };

    const handleToggleExpandDocument = (docId) => {
        setExpandedDocument(prevDoc => prevDoc === docId ? null : docId);
        if (showAll) {
            handleShowAll();
        }
    };

    const handleShowAll = () => {
        setShowAll(prevState => !prevState);
        if (!showAll) {
            setExpandedDocument(null);
        }
    };

    const handleToggleEditMode = async (docId) => {
        if (editingDocId === docId) {
            // Exit edit mode and discard changes
            setEditingDocId(null);
            setStaticData(prevData => ({
                ...prevData,
                [docId]: originalData // Restore original data for the document
            }));
            setOriginalData({}); // Clear original data
            setHasChanges(false); // Reset changes on cancel
        } else {
            if (editingDocId !== null) {
                // A document is currently being edited
                const currentDocData = staticData[editingDocId];

                if (JSON.stringify(currentDocData) !== JSON.stringify(originalData)) {
                    // If there are unsaved changes, confirm before switching
                    const confirmSwitch = window.confirm("Du har foretaget ændringer der ikke er gemt endnu. Vil du gemme dine ændringer?");
                    if (confirmSwitch) {
                        // Save the current document before switching
                        await handleSaveData(editingDocId);
                    } else {
                        // Discard changes
                        setStaticData(prevData => ({
                            ...prevData,
                            [editingDocId]: originalData // Restore original data for the document
                        }));
                    }
                    setHasChanges(false); // Reset for new edit
                }
            }

            // Enter edit mode for the new document
            setEditingDocId(docId);
            setOriginalData(staticData[docId]); // Save original data for cancellation
        }
    };


    const sortedSubFieldNames = ['x', 'y', 'z'];

    const renderSubFields = (docId, fieldName) => (
        <tr className="expanded-element-item">
            <td className="staticDataInputLineup">{fieldName}</td>
            <td>
                <div className="subfield-container">
                    {sortedSubFieldNames.map((subFieldName, index) => (
                        <div key={index} className="subfield-item">
                            <label>{subFieldName}:</label>
                            <input
                                type="number"  
                                value={staticData[docId][fieldName][subFieldName]}
                                onChange={(e) => handleFieldChange(e, docId, fieldName, subFieldName)}
                                className={`form-control ${editingDocId === docId ? '' : 'input-not-editable'}`}
                                disabled={editingDocId !== docId}
                            />
                        </div>
                    ))}
                </div>
            </td>
        </tr>
    );

    return (
        <div className="element-data-container">
            {!isDataLoaded ? (
                <p>Indlæser...</p>
            ) : (
                <>
                    <div className="category-button-container">
                        <div className="category-button-grid">
                            <button
                                className={`category-button ${showAll ? 'active' : ''}`}
                                onClick={handleShowAll}
                            >
                                Vis alle
                            </button>
                            {Object.keys(staticData).map(docId => (
                                <button
                                    key={docId}
                                    className={`category-button ${expandedDocument === docId ? 'active' : ''}`}
                                    onClick={() => handleToggleExpandDocument(docId)}
                                >
                                    {docId}
                                </button>
                            ))}
                        </div>
                    </div>
                    <div className="category-details">
                        {showAll ? (
                            <div>
                                {Object.keys(staticData).map(docId => (
                                    <div key={docId} className="expanded-row">
                                        <div className="document-container">
                                            <h2>{docId}</h2>
                                            <table className="element-table">
                                                <tbody>
                                                    {Object.keys(staticData[docId])
                                                        .filter(fieldName => fieldName !== 'LastUpdated')
                                                        .sort()
                                                        .map(fieldName => {
                                                            if (typeof staticData[docId][fieldName] === 'object') {
                                                                return renderSubFields(docId, fieldName);
                                                            } else {
                                                                return (
                                                                    <tr key={fieldName} className="expanded-element-item">
                                                                        <td className="staticDataInputLineup">{fieldName.value === "firstBuildItem" ? fieldName+"44" : fieldName}</td>
                                                                        <td>
                                                                            <input
                                                                                type={fieldName == "firstBuildItem" ? "textnumber" : "number" }
                                                                                value={staticData[docId][fieldName]}
                                                                                onChange={(e) => handleFieldChange(e, docId, fieldName)}
                                                                                className={`form-control ${editingDocId === docId ? '' : 'input-not-editable'}`}
                                                                                disabled={editingDocId !== docId}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            }
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            <div className='button-actions'>
                                                {editingDocId !== docId && ( // Show edit button only if not editing this document
                                                    <button
                                                        className="icon-button edit-element-button"
                                                        onClick={() => handleToggleEditMode(docId)}
                                                        data-tooltip="Rediger"
                                                    >
                                                        <i className="bi bi-pencil"></i>
                                                    </button>
                                                )}
                                                {editingDocId === docId && ( // Show save and cancel buttons if editing
                                                    <>
                                                        <button
                                                            className={`icon-button save-element-button`}
                                                            onClick={() => handleSaveData(docId)} // Save function
                                                            data-tooltip="Gem Data"
                                                        >
                                                            <i className="bi bi-floppy"></i>
                                                        </button>
                                                        <button
                                                            className="icon-button"
                                                            onClick={() => handleToggleEditMode(docId)} // Cancel editing
                                                            data-tooltip="Annuller"
                                                        >
                                                            <i className="bi bi-arrow-return-left"></i>
                                                        </button>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            Object.keys(staticData).map(docId => (
                                expandedDocument === docId && (
                                    <div key={docId} className="expanded-row">
                                        <div className="document-container">
                                            <h2>{docId}</h2>
                                            <table className="element-table">
                                                <tbody>
                                                    {Object.keys(staticData[docId])
                                                        .filter(fieldName => fieldName !== 'LastUpdated')
                                                        .sort()
                                                        .map(fieldName => {
                                                            if (typeof staticData[docId][fieldName] === 'object') {
                                                                return renderSubFields(docId, fieldName);
                                                            } else {
                                                                return (
                                                                    <tr key={fieldName} className="expanded-element-item">
                                                                        <td className="staticDataInputLineup">{fieldName}</td>
                                                                        <td>
                                                                            <input
                                                                                type={fieldName == "firstBuildItem" ? "textnumber" : "number" }
                                                                                value={staticData[docId][fieldName]}
                                                                                onChange={(e) => handleFieldChange(e, docId, fieldName)}
                                                                                className={`form-control ${editingDocId === docId ? '' : 'input-not-editable'}`}
                                                                                disabled={editingDocId !== docId}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            }
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            <div className='button-actions'>
                                                {editingDocId !== docId && ( // Show edit button only if not editing this document
                                                    <button
                                                        className="icon-button edit-element-button"
                                                        onClick={() => handleToggleEditMode(docId)}
                                                        data-tooltip="Rediger"
                                                    >
                                                        <i className="bi bi-pencil"></i>
                                                    </button>
                                                )}
                                                {editingDocId === docId && ( // Show save and cancel buttons if editing
                                                    <>
                                                        <button
                                                            className="icon-button save-element-button"
                                                            onClick={() => handleSaveData(docId)} // Save function
                                                            data-tooltip="Gem Data"
                                                        >
                                                            <i className="bi bi-floppy"></i>
                                                        </button>
                                                        <button
                                                            className="icon-button"
                                                            onClick={() => handleToggleEditMode(docId)} // Cancel editing
                                                            data-tooltip="Annuller"
                                                        >
                                                            <i className="bi bi-arrow-return-left"></i>
                                                        </button>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )
                            ))
                        )}
                    </div>
                </>
            )}
        </div>
    );
}
