import React, { useEffect, useState } from 'react';
import { onSnapshot, doc, updateDoc, deleteField } from "firebase/firestore";
import { db } from '../FirebaseConfig';
import '../CMSComponents/css/ComponentStyling.css';
import { imageNameMapping } from './ImageUrls';
import { setGlobalUpdated } from './Functions/GlobalUpdated';
import DialoguePreview from './css/Images/Dialogue-preview.png';

export default function DialogueDataTable() {
    // State to hold dialogue data grouped by category
    const [dialogueData, setDialogueData] = useState({});
    // State to track if the data has been loaded from Firestore
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    // State to keep track of which category is currently expanded
    const [expandedCategory, setExpandedCategory] = useState("All");
    // State to manage which dialogues are expanded
    const [expandedDialogues, setExpandedDialogues] = useState({});
    // State to track changes made to dialogue texts
    const [dialogueChanges, setDialogueChanges] = useState({});
    // State for search input
    const [searchTerm, setSearchTerm] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newDialogueText, setNewDialogueText] = useState(['']);
    const [newDialogueCategory, setNewDialogueCategory] = useState('');
    const [editingDialogues, setEditingDialogues] = useState({});

    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
    const [previewText, setPreviewText] = useState('');



    // Mapping of category names to their display names and images
    const categoryButtonData = {
        "All-single": { name: "Alle Dialoger", image: imageNameMapping.Recycle },
        "All": {
            name: "Alle Dialoger",
            image: [
                imageNameMapping.Recycle,
                imageNameMapping.Battery,
                imageNameMapping.Glass,
                imageNameMapping.Iron,
                imageNameMapping.WaterBottle,
                imageNameMapping.Rubber,
                imageNameMapping.Fabric,
                imageNameMapping.Wood
            ]
        },
        Batteries: { name: "Batteri dialog", image: imageNameMapping.Battery },
        Glass: { name: "Glas dialog", image: imageNameMapping.Glass },
        Metal: { name: "Metal dialog", image: imageNameMapping.Iron },
        NIR: { name: "NIR dialog", image: imageNameMapping.Recycle },
        Plastic: { name: "Plastik dialog", image: imageNameMapping.WaterBottle },
        Rubber: { name: "Gummi dialog", image: imageNameMapping.Rubber },
        Textile: { name: "Textil dialog", image: imageNameMapping.Fabric },
        Wood: { name: "Træ dialog", image: imageNameMapping.Wood }
    };

    useEffect(() => {
        // Load data from localStorage with error handling
        try {
            const localData = localStorage.getItem('dialogueData');
            if (localData) {
                setDialogueData(JSON.parse(localData));
                setIsDataLoaded(true);
            }
        } catch (error) {
            console.error('Error loading data from localStorage:', error);
        }

        // Set up a real-time listener for Firestore updates
        const docRef = doc(db, 'DialogueData', 'AllDialogues');
        const unsubscribe = onSnapshot(docRef, (doc) => {
            if (doc.exists()) {
                const data = doc.data();
                const updatedDialogueData = {};

                Object.keys(data).forEach(docId => {
                    const { dialogueTexts, LastUpdated } = data[docId];
                    const category = docId.split(' ')[0]; // Extract category

                    if (!updatedDialogueData[category]) {
                        updatedDialogueData[category] = [];
                    }

                    updatedDialogueData[category].push({
                        documentId: docId,
                        category,
                        dialogueTexts: dialogueTexts || [],
                        LastUpdated: LastUpdated ? LastUpdated.toDate() : null,
                    });
                });

                // Update state and localStorage
                try {
                    setDialogueData(updatedDialogueData);
                    localStorage.setItem('dialogueData', JSON.stringify(updatedDialogueData));
                    setIsDataLoaded(true);
                } catch (error) {
                    console.error('Error updating localStorage:', error);
                }
            }
        });

        // Clean up Firestore subscription on component unmount
        return () => unsubscribe();
    }, []);

    const openAddDialogueModal = (category) => {
        setNewDialogueCategory(category);
        setNewDialogueText(['']);
        setIsModalOpen(true);
    };

    // Function to open the modal with the text to preview
    const handleOpenPreviewModal = (text) => {
        setPreviewText(text);
        setIsPreviewModalOpen(true);
    };

    // Function to close the modal
    const handleClosePreviewModal = () => {
        setIsPreviewModalOpen(false);
        setPreviewText(''); // Clear the preview text
    };

    // Function to handle category toggle
    const handleToggleExpandCategory = (category) => {
        // Toggle the expanded category
        setExpandedCategory(prevCategory => prevCategory === category ? null : category);
        // Reset expanded dialogues and dialogue changes
        setExpandedDialogues({});
        setDialogueChanges({});
    };

    // Function to handle dialogue row expansion
    const handleToggleExpandDialogue = (documentId) => {
        setExpandedDialogues(prevState => ({
            ...prevState,
            [documentId]: !prevState[documentId],
        }));
    };

    // Function to handle changes in dialogue text
    const handleDialogueChange = (e, docId, index) => {
        const { value } = e.target;

        setDialogueData(prevData => {
            const updatedDialogues = getAllDialogues().map(dialogue =>
                dialogue.documentId === docId
                    ? { ...dialogue, dialogueTexts: dialogue.dialogueTexts.map((text, i) => i === index ? value : text) }
                    : dialogue
            );
            return Object.keys(prevData).reduce((acc, category) => {
                acc[category] = updatedDialogues.filter(dialogue => dialogue.documentId.startsWith(category));
                return acc;
            }, {});
        });

        setDialogueChanges(prevChanges => ({
            ...prevChanges,
            [docId]: {
                ...prevChanges[docId],
                [index]: value
            }
        }));
    };

    // Function to save changes to a dialogue
    const handleSaveDialogue = async (docId) => {
        const dialogueToUpdate = getAllDialogues().find(dialogue => dialogue.documentId === docId);

        if (!dialogueToUpdate) {
            alert("Kunne ikke gemme dialog. Ugyldige dialogdata.");
            return;
        }

        const docRef = doc(db, 'DialogueData', 'AllDialogues');

        try {
            const updatedDialogueTexts = dialogueChanges[docId]
                ? dialogueToUpdate.dialogueTexts.map((text, i) => dialogueChanges[docId][i] || text)
                : dialogueToUpdate.dialogueTexts;

            const updatePayload = {
                [`${docId}.dialogueTexts`]: updatedDialogueTexts,
                [`${docId}.LastUpdated`]: new Date()
            };

            await updateDoc(docRef, updatePayload);
            await setGlobalUpdated();
            alert("Dialog opdateret med succes!");

            // Reset dialogue changes and exit edit mode
            setDialogueChanges(prevChanges => ({ ...prevChanges, [docId]: {} }));

            // Exit edit mode for the current dialogue
            setEditingDialogues(prevEditing => ({ ...prevEditing, [docId]: false }));
        } catch (error) {
            alert("Kunne ikke opdatere dialog.");
        }
    };


    // Function to add a new text field to a dialogue
    const handleAddField = (docId) => {
        setDialogueData(prevData => {
            const updatedDialogues = getAllDialogues().map(dialogue =>
                dialogue.documentId === docId
                    ? { ...dialogue, dialogueTexts: [...dialogue.dialogueTexts, ''] }
                    : dialogue
            );
            return Object.keys(prevData).reduce((acc, category) => {
                acc[category] = updatedDialogues.filter(dialogue => dialogue.documentId.startsWith(category));
                return acc;
            }, {});
        });
    };

    // Function to remove a text field from a dialogue
    const handleRemoveField = (docId, index) => {
        setDialogueData(prevData => {
            // Ensure getAllDialogues returns the correct dialogues
            const updatedDialogues = getAllDialogues().map(dialogue => {
                // Check if this is the correct dialogue
                if (dialogue.documentId === docId) {
                    // Safely remove the field (index) from dialogueTexts
                    const updatedTexts = dialogue.dialogueTexts.filter((_, i) => i !== index);

                    // Ensure there are no undefined values and handle empty array
                    if (updatedTexts.length === 0) {
                        alert('Dialog kan ikke have tomme tekster. Tilføj venligst et felt, før du fjerner andre.');
                        return dialogue; // Don't allow an empty dialogueTexts array
                    }

                    // Return the updated dialogue with modified dialogueTexts
                    return { ...dialogue, dialogueTexts: updatedTexts };
                }

                return dialogue; // Return unchanged dialogues
            });

            // Rebuild dialogueData with updated dialogues
            return Object.keys(prevData).reduce((acc, category) => {
                acc[category] = updatedDialogues.filter(dialogue => dialogue.documentId.startsWith(category));
                return acc;
            }, {});
        });

        // Now, update Firestore
        const docRef = doc(db, 'DialogueData', 'AllDialogues');

        const updatedTextsForFirestore = dialogueData[docId]?.dialogueTexts.filter((_, i) => i !== index);

        // Ensure no undefined values are passed to Firestore
        if (!updatedTextsForFirestore || updatedTextsForFirestore.includes(undefined)) {
            console.error('Error: Attempting to update Firestore with undefined values');
            return;
        }

        const updatePayload = {
            [`${docId}.dialogueTexts`]: updatedTextsForFirestore,
            [`${docId}.LastUpdated`]: new Date(),
        };

        // Update Firestore document
        updateDoc(docRef, updatePayload)
            .then(() => {
                console.log('Field removed successfully');
            })
            .catch(error => {
                console.error('Error removing field: ', error);
            });
    };

    // Function to add a new dialogue to a category
    const handleCreateNewDialogue = async (category) => {
        if (!category) {
            alert("Vælg venligst en kategori først.");
            return;
        }

        // Prepare new dialogue data
        const highestNumber = (dialogueData[category] || [])
            .reduce((max, dialogue) => {
                const match = dialogue.documentId.match(/^(\D+)(\d+)$/);
                if (match && match[1] === `${category} Dialogue `) {
                    const number = parseInt(match[2], 10);
                    return Math.max(max, number);
                }
                return max;
            }, 0);

        const newDocId = `${category} Dialogue ${highestNumber + 1}`;
        const newDialogue = {
            [newDocId]: {
                dialogueTexts: newDialogueText,
                LastUpdated: new Date()
            }
        };

        try {
            // Update Firestore with new dialogue
            const docRef = doc(db, 'DialogueData', 'AllDialogues');
            await updateDoc(docRef, newDialogue);

            // Optimistic UI Update: Directly add the new dialogue to the local state
            setDialogueData(prevData => ({
                ...prevData,
                [category]: [
                    ...prevData[category]?.filter(d => d.documentId !== newDocId), // Ensure no duplicates
                    {
                        documentId: newDocId,
                        category,
                        dialogueTexts: newDialogueText,
                        LastUpdated: new Date()
                    }
                ]
            }));

            // Close modal and reset input fields
            setIsModalOpen(false);
            setNewDialogueText(['']);

            // Calls Custom funktion to set gloabal last updated
            await setGlobalUpdated();

            alert("Ny dialog tilføjet med succes!");
        } catch (error) {
            console.error("Error adding new dialogue: ", error);
            alert("Kunne ikke tilføje ny dialog.");
        }
    };

    const handleToggleEditDialogue = (documentId) => {
        setEditingDialogues((prev) => ({
            ...prev,
            [documentId]: !prev[documentId], // Toggle the edit mode for the given dialogue
        }));
    };

    // Cancel the editing mode
    const handleCancelEdit = (documentId) => {
        setEditingDialogues((prev) => ({
            ...prev,
            [documentId]: false, // Exit edit mode
        }));
    };

    // Function to remove a dialogue
    const handleRemoveDialogue = async (docId, category) => {
        const confirmDelete = window.confirm('WARNING! Are you sure you want to delete this dialogue?');

        if (confirmDelete) {
            try {
                // First, remove the dialogue from the Firestore document using deleteField
                const docRef = doc(db, 'DialogueData', 'AllDialogues'); // Reference the document containing the dialogues
                const updatePayload = {
                    [`${docId}`]: deleteField(), // Use Firestore's deleteField function to remove the entire dialogue
                };

                // Update the Firestore document
                await updateDoc(docRef, updatePayload);

                // After updating Firestore, remove the dialogue from the local state
                setDialogueData(prevData => {
                    const updatedDialogues = prevData[category].filter(dialogue => dialogue.documentId !== docId);
                    return { ...prevData, [category]: updatedDialogues };
                });

                // Calls Custom funktion to set gloabal last updated
                await setGlobalUpdated();


                alert("Dialog fjernet med succes!");
            } catch (error) {
                console.error("Error removing dialogue: ", error);
                alert("Kunne ikke fjerne dialog");
            }
        }
    };

    // Function to get all dialogues across all categories
    const getAllDialogues = () => {
        return Object.keys(dialogueData).reduce((acc, category) => {
            return [...acc, ...dialogueData[category]];
        }, []);
    };

    // Function to filter dialogues based on search term
    const filterDialoguesBySearchTerm = (dialogues) => {
        if (!searchTerm) return dialogues;

        // Filter dialogues where any text field contains the search term
        return dialogues.filter(dialogue =>
            dialogue.dialogueTexts.some(text => typeof text === 'string' && text.toLowerCase().includes(searchTerm.toLowerCase()))
        );
    };

    // Determine which dialogues to display based on the selected category and search term
    const dialoguesToShow = (expandedCategory === "All"
        ? getAllDialogues() // Get all dialogues
        : dialogueData[expandedCategory] || []) // Get dialogues for the selected category
        .sort((a, b) => a.documentId.localeCompare(b.documentId)) // Sort alphabetically by documentId
        .filter(dialogue => filterDialoguesBySearchTerm([dialogue]).length > 0); // Apply search filter



    return (
        <div className="element-data-container">
            {!isDataLoaded ? (
                <p>Loading...</p>
            ) : (
                <>
                    {/* Category Buttons */}
                    <div className="category-button-container">
                        <div className="category-button-grid">
                            <button
                                className={`category-button ${expandedCategory === "All" ? 'active' : ''}`}
                                onClick={() => handleToggleExpandCategory("All")}
                            >
                                Alle Dialoger
                            </button>
                            {Object.keys(dialogueData)
                                .sort((a, b) => a.localeCompare(b))
                                .map((category) => (
                                    <button
                                        key={category}
                                        className={`category-button ${expandedCategory === category ? 'active' : ''}`}
                                        onClick={() => handleToggleExpandCategory(category)}
                                    >
                                        <img src={categoryButtonData[category]?.image} alt={category} className="category-icon" />
                                        {categoryButtonData[category]?.name || category}
                                    </button>
                                ))}
                        </div>
                    </div>

                    {/* Category Details */}
                    {expandedCategory && (
                        <div className="category-details">
                            {/* Search Input (Only visible for 'All Dialoger') */}
                            {expandedCategory === "All" && (
                                <div className="search-container">
                                    <input
                                        type="text"
                                        placeholder="Søg i dialoger..."
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        className="search-input"
                                    />
                                </div>
                            )}
                            <br></br>
                            {/* Add Object Button */}
                            {expandedCategory !== "All" && (
                                <div className="add-object-button-container">
                                    <button
                                        className="add-object-button"
                                        onClick={() => setIsModalOpen(true)}
                                    >
                                        <i className="bi bi-plus-circle add-icon"></i>
                                        Opret ny dialog
                                    </button>
                                </div>
                            )}

                            {/* Table with dialogues */}
                            <table className="element-table">
                                <tbody>
                                    {dialoguesToShow.map(dialogue => (
                                        <React.Fragment key={dialogue.documentId}>
                                            {/* Dialogue Row */}
                                            <tr>
                                                <td>
                                                    <button
                                                        className={`full-width-button ${expandedDialogues[dialogue.documentId] ? 'expanded' : ''}`}
                                                        onClick={() => handleToggleExpandDialogue(dialogue.documentId)}
                                                    >
                                                        {categoryButtonData[dialogue.category]?.image && (
                                                            <img
                                                                src={categoryButtonData[dialogue.category].image}
                                                                alt={categoryButtonData[dialogue.category].name}
                                                                className="category-icon"
                                                            />
                                                        )}
                                                        {dialogue.documentId}
                                                        <i className={`bi ${expandedDialogues[dialogue.documentId] ? 'bi-chevron-double-up' : 'bi-chevron-double-down'}`}></i>
                                                    </button>
                                                </td>
                                            </tr>

                                            {/* Expanded Row */}
                                            {expandedDialogues[dialogue.documentId] && (
                                                <tr className="expanded-row expanded">
                                                    <td>
                                                        <div className="expanded-element-container">
                                                            {/* Input Fields (Editable only in Edit Mode) */}
                                                            <div className="element-fields-container">
                                                                {dialogue.dialogueTexts.map((text, index) => (
                                                                    <div key={index} className="expanded-element-item tooltip-container">
                                                                        <input
                                                                            type="text"
                                                                            value={text}
                                                                            onChange={(e) => handleDialogueChange(e, dialogue.documentId, index)}
                                                                            className={`form-control ${!editingDialogues ? 'input-not-editable' : ''}`}

                                                                            readOnly={!editingDialogues[dialogue.documentId]} // Make it read-only if not in edit mode
                                                                            onClick={() => !editingDialogues[dialogue.documentId] && handleOpenPreviewModal(text)} // Open modal if not in edit mode
                                                                        />
                                                                        {!editingDialogues[dialogue.documentId] && (
                                                                            <span className="tooltip-text">Klik for at se forhåndsvisning</span>
                                                                        )}

                                                                        {editingDialogues[dialogue.documentId] && (
                                                                            <button
                                                                                className="icon-button remove-field-button"
                                                                                onClick={() => handleRemoveField(dialogue.documentId, index)}
                                                                                data-tooltip="Slet tekstfelt"
                                                                            >
                                                                                <i className="bi bi-x-circle"></i>
                                                                            </button>
                                                                        )}
                                                                    </div>
                                                                ))}
                                                            </div>

                                                            {/* Edit Mode Actions */}
                                                            <div className="button-actions">
                                                                {/* Show Edit Button */}
                                                                {!editingDialogues[dialogue.documentId] ? (
                                                                    <button
                                                                        className="icon-button edit-element-button"
                                                                        onClick={() => handleToggleEditDialogue(dialogue.documentId)}
                                                                        data-tooltip="Rediger dialog"
                                                                    >
                                                                        <i className="bi bi-pencil"></i>
                                                                    </button>
                                                                ) : (
                                                                    <>
                                                                        {/* Knapper kun synlige i "Edit" mode */}
                                                                        <button
                                                                            className="icon-button add-field-button"
                                                                            onClick={() => handleAddField(dialogue.documentId)}
                                                                            data-tooltip="Tilføj tekstfelt"
                                                                        >
                                                                            <i className="bi bi-plus-circle"></i>
                                                                        </button>
                                                                        <button
                                                                            className="icon-button save-element-button"
                                                                            onClick={() => handleSaveDialogue(dialogue.documentId)}
                                                                            data-tooltip="Gem ændringer"
                                                                        >
                                                                            <i className="bi bi-floppy"></i>
                                                                        </button>
                                                                        <button
                                                                            className="icon-button cancel-edit-button"
                                                                            onClick={() => handleCancelEdit(dialogue.documentId)}
                                                                            data-tooltip="Annuller"
                                                                        >
                                                                            <i className="bi bi-arrow-return-left"></i>
                                                                        </button>
                                                                        <div className="remove-button-container">
                                                                            <button
                                                                                className="icon-button remove-element-button"
                                                                                onClick={() => handleRemoveDialogue(dialogue.documentId, expandedCategory)}
                                                                                data-tooltip="Slet dialog"
                                                                            >
                                                                                <i className="bi bi-trash3-fill"></i>
                                                                            </button>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}

                    {/* Modal for Adding New Dialogue */}
                    {isModalOpen && (
                        <div className="dialogue-modal-overlay">
                            <div className="dialogue-modal-content">
                                <div className="dialogue-modal-header">
                                    <h2>Tilføj Ny Dialog</h2>
                                    <button
                                        className="icon-button"
                                        onClick={() => setIsModalOpen(false)}
                                        data-tooltip="Annuller"
                                    >
                                        <i className="bi bi-x-lg"></i>
                                    </button>
                                </div>
                                <div className="dialogue-modal-body">
                                    {newDialogueText.map((text, index) => (
                                        <div key={index} className="expanded-element-item">
                                            <input
                                                type="text"
                                                value={text}
                                                onChange={(e) => {
                                                    const newTexts = [...newDialogueText];
                                                    newTexts[index] = e.target.value;
                                                    setNewDialogueText(newTexts);
                                                }}
                                                className="dialogue-modal-textarea"
                                                placeholder="Indtast dialog tekst"
                                            />
                                            {index > 0 && (
                                                <button
                                                    className="icon-button remove-field-button"
                                                    onClick={() => {
                                                        const newTexts = newDialogueText.filter((_, i) => i !== index);
                                                        setNewDialogueText(newTexts);
                                                    }}
                                                    data-tooltip="Fjern tekstfelt"
                                                >
                                                    <i className="bi bi-x-circle"></i>
                                                </button>
                                            )}
                                        </div>
                                    ))}
                                    <div className="dialogue-modal-addbutton">
                                        <button
                                            className="icon-button add-field-button"
                                            onClick={() => setNewDialogueText([...newDialogueText, ''])}
                                            data-tooltip="Tilføj ekstra tekstfelt"
                                        >
                                            <i className="bi bi-plus-circle"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="dialogue-modal-footer">
                                    <button
                                        className="icon-button save-element-button"
                                        onClick={() => {
                                            handleCreateNewDialogue(expandedCategory);
                                            setIsModalOpen(false);
                                        }}
                                        data-tooltip="Opret ny dialog"
                                    >
                                        <i className="bi bi-floppy"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    {/* Preview Modal */}
                    {isPreviewModalOpen && (
                        <div className="dialogue-preview-modal-overlay">
                            <div className="dialogue-preview-modal-content">
                                <div className="dialogue-preview-wrapper" onClick={() => handleClosePreviewModal(false)}>
                                    <img src={DialoguePreview} alt="Dialogue Preview" className="dialogue-preview-image" />
                                    <div className="dialogue-preview-text">{previewText}</div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}
