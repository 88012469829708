import React, { useEffect, useState, useRef } from 'react';
import { collection, onSnapshot, doc, updateDoc } from "firebase/firestore";
import { db } from '../FirebaseConfig';
import '../CMSComponents/css/ComponentStyling.css';
import ImageSelector from './ImageSelector'; // Import ImageSelector
import { imageUrls } from './ImageUrls'; // Import imageUrls
import { setGlobalUpdated } from './Functions/GlobalUpdated';

// Component for auto-expanding textarea
const ExpandableTextarea = ({ value, onChange, ...props }) => {
    const textareaRef = useRef(null);

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto'; // Reset height to auto
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set height to scrollHeight
        }
    }, [value]); // Re-run whenever value changes

    return (
        <textarea
            ref={textareaRef}
            value={value}
            onChange={onChange}
            {...props}
        />
    );
};

export default function InformationSign() {
    const [infoSignsData, setInfoSignsData] = useState({});
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [selectedSign, setSelectedSign] = useState("All");
    const [expandedSign, setExpandedSign] = useState(null);
    const [editMode, setEditMode] = useState(null);
    const [signChanges, setSignChanges] = useState({});
    const [selectedImage, setSelectedImage] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [showModal, setShowModal] = useState(false); // State for modal visibility
    const [currentEditingSign, setCurrentEditingSign] = useState(null);

    useEffect(() => {
        // Load data from localStorage with error handling
        try {
            const localData = localStorage.getItem('InformationSigns');
            if (localData) {
                setInfoSignsData(JSON.parse(localData));
                setIsDataLoaded(true);
            }
        } catch (error) {
            console.error('Fejl under indlæsning fra localStorage:', error);
        }

        // Set up a real-time listener for Firestore updates
        const unsubscribe = onSnapshot(collection(db, 'InformationSigns'), (snapshot) => {
            if (snapshot) {
                const updatedInfoSignsData = {};

                snapshot.forEach(doc => {
                    const data = doc.data();
                    const documentId = doc.id;

                    updatedInfoSignsData[documentId] = {
                        documentId,
                        title: data.Title || documentId,
                        description: data.Description || '',
                        image: data.Image !== undefined ? parseInt(data.Image, 10) : null,
                        LastUpdated: data.LastUpdated ? data.LastUpdated.toDate() : null,
                    };
                });

                // Update state and localStorage
                try {
                    setInfoSignsData(updatedInfoSignsData);
                    localStorage.setItem('InformationSigns', JSON.stringify(updatedInfoSignsData));
                    setIsDataLoaded(true);
                } catch (error) {
                    console.error('Fejl ved indlæsning fra localStorage:', error);
                }
            }
        }, (error) => {
            console.error("Fejl ved indlæsning af data: ", error);
        });

        // Clean up Firestore subscription on component unmount
        return () => unsubscribe();
    }, []);

    const handleSelectSign = (sign) => {
        if (selectedSign === sign) {
            setSelectedSign(null); // Deselect the sign
        } else {
            setSelectedSign(sign); // Select the clicked sign
            if (selectedSign !== "All") {
                setExpandedSign(sign);
            }
        }
    };

    const handleExpandToggle = (documentId) => {
        setExpandedSign(prevSign => prevSign === documentId ? null : documentId);
    };

    const handleEditToggle = (documentId) => {
        setEditMode(prevMode => prevMode === documentId ? null : documentId);
        setCurrentEditingSign(documentId);

        // Reset selected image to current sign's image when editing
        if (infoSignsData[documentId]) {
            setSelectedImage(infoSignsData[documentId].image); // Set to current sign's image
        }
    };

    const handleChange = (e, docId, field) => {
        const { value } = e.target;

        setInfoSignsData(prevData => ({
            ...prevData,
            [docId]: {
                ...prevData[docId],
                [field]: value
            }
        }));

        setSignChanges(prevChanges => {
            const originalValue = infoSignsData[docId][field];

            if (value === originalValue) {
                // If the new value matches the original, remove it from signChanges
                const { [field]: removedField, ...remainingChanges } = prevChanges[docId] || {};

                // If no other fields have changes, remove the entire docId from signChanges
                return Object.keys(remainingChanges).length > 0
                    ? { ...prevChanges, [docId]: remainingChanges }
                    : Object.keys(prevChanges).reduce((acc, key) => {
                        if (key !== docId) acc[key] = prevChanges[key];
                        return acc;
                    }, {});
            } else {
                // Otherwise, update signChanges with the new value
                return {
                    ...prevChanges,
                    [docId]: {
                        ...prevChanges[docId],
                        [field]: value
                    }
                };
            }
        });
    };

    const handleCancelEdit = (docId) => {
        // Revert infoSignsData back to the original data stored in localStorage (or Firestore)
        const originalData = JSON.parse(localStorage.getItem('InformationSigns')) || {};

        setInfoSignsData(prevData => ({
            ...prevData,
            [docId]: originalData[docId] // Revert to original data for the specific sign
        }));

        // Remove any changes made to this sign from signChanges
        setSignChanges(prevChanges => {
            const { [docId]: removedChanges, ...remainingChanges } = prevChanges;
            return remainingChanges;
        });

        // Exit edit mode
        setEditMode(null);
    };



    const handleSaveSign = async (docId) => {
        const signToUpdate = infoSignsData[docId];

        if (!signToUpdate) {
            console.error("Ugyldig data.");
            alert("Der opstod en fejl med at gemme data. Ugyldig data.");
            return;
        }

        const docRef = doc(db, 'InformationSigns', docId);
        try {
            await updateDoc(docRef, {
                Title: signToUpdate.title,
                Description: signChanges[docId]?.description || signToUpdate.description,
                Image: signChanges[docId]?.image !== undefined ? signChanges[docId].image : signToUpdate.image,
                LastUpdated: new Date()
            });

            // Calls Custom function to set global last updated
            await setGlobalUpdated();

            alert("Skiltet er blevet opdateret!");
            setSignChanges(prevChanges => ({ ...prevChanges, [docId]: {} }));
            setEditMode(null);
            setShowModal(false); // Close modal when saving
        } catch (error) {
            console.error("Fejl under opdatering: ", error);
            alert("Der opstod en fejl under opdateringen af Informationsskiltet.");
        }
    };

    const handleSelectImage = (imageId) => {
        setSelectedImage(imageId);
        setSignChanges(prevChanges => ({
            ...prevChanges,
            [currentEditingSign]: {
                ...prevChanges[currentEditingSign],
                image: imageId
            }
        }));
        setShowModal(false); // Close modal after selecting image
    };

    const openModal = (documentId) => {
        setCurrentEditingSign(documentId); // Set the current editing sign
        setSelectedImage(infoSignsData[documentId].image); // Set selected image to current sign's image
        setShowModal(true);
    };

    const closeModal = () => setShowModal(false);

    // Function to get all signs across all categories
    const GetAllSigns = () => {
        return Object.keys(infoSignsData).reduce((acc, sign) => {
            return [...acc, infoSignsData[sign]];
        }, []);
    };

    // Function to filter signs based on search term
    const FilterSignsBySearchTerm = (infoSigns) => {
        if (!searchTerm) return infoSigns;

        // Filter signs where the title or description contains the search term
        return infoSigns.filter(infoSign =>
        (infoSign.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            infoSign.description.toLowerCase().includes(searchTerm.toLowerCase()))
        );
    };

    const signsToShow = selectedSign === "All"
        ? FilterSignsBySearchTerm(GetAllSigns())
        : infoSignsData[selectedSign]
            ? FilterSignsBySearchTerm([infoSignsData[selectedSign]]) // Wrap the single sign object in an array
            : [];

    const removeColorTags = (text) => {
        // Regular expression to match color tags
        return text.replace(/<color[^>]*>(.*?)<\/color>/g, '$1');
    };

    return (
        <div className="element-data-container">
            {!isDataLoaded ? (
                <p>Indlæser...</p>
            ) : (
                <>
                    <div className="category-button-container">
                        <div className="category-button-grid">
                            <button
                                className={`category-button ${selectedSign === "All" ? 'active' : ''}`}
                                onClick={() => handleSelectSign("All")}
                            >
                                Alle Skilte
                            </button>
                        </div>
                    </div>
                    {selectedSign && (
                        <div className="category-details">
                            {/* Search input field */}
                            <div className="search-container">
                                <input
                                    type="text"
                                    placeholder="Søg i informationsskilte..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    className="search-input"
                                />
                            </div>
                            <br></br>
                            {/* Signs table */}
                            <table className="element-table">
                                <tbody>
                                    {signsToShow.map(sign => (
                                        <React.Fragment key={sign.documentId}>
                                            <tr>
                                                <td>
                                                    <button
                                                        className={`full-width-button ${expandedSign === sign.documentId ? 'expanded' : ''}`}
                                                        onClick={() => handleExpandToggle(sign.documentId)}
                                                    >
                                                        <img
                                                            src={imageUrls.find(img => img.id === sign.image)?.src}
                                                            alt={sign.title}
                                                            className="category-icon"
                                                        />
                                                        {sign.title}
                                                        <i className={`bi ${expandedSign === sign.documentId ? 'bi-chevron-double-up' : 'bi-chevron-double-down'}`}></i>
                                                    </button>
                                                </td>
                                            </tr>
                                            {expandedSign === sign.documentId && (
                                                <tr className="expanded-row expanded">
                                                    <td>
                                                        <div className="expanded-sign-container">
                                                            {/* Title Field */}
                                                            <div className="expanded-sign-item">
                                                                <strong>Titel:</strong>
                                                                <input
                                                                    type="text"
                                                                    value={editMode === sign.documentId ? sign.title : removeColorTags(sign.title)}
                                                                    onChange={(e) => handleChange(e, sign.documentId, 'title')}
                                                                    className={`expanded-sign-title form-control ${editMode !== sign.documentId ? 'input-not-editable' : ''}`}
                                                                    disabled={editMode !== sign.documentId} // Disable if not in edit mode
                                                                />

                                                            </div>
                                                            {/* Description Field */}
                                                            <div className="expanded-sign-item">
                                                                <strong>Beskrivelse:</strong>
                                                                <ExpandableTextarea
                                                                    value={editMode === sign.documentId ? sign.description : removeColorTags(sign.description)}
                                                                    onChange={(e) => handleChange(e, sign.documentId, 'description')}
                                                                    className={`expanded-sign-description form-control ${editMode !== sign.documentId ? 'input-not-editable' : ''}`}
                                                                    disabled={editMode !== sign.documentId} // Disable if not in edit mode
                                                                />
                                                            </div>
                                                            {/* Image Field */}
                                                            <div className="expanded-sign-item">
                                                                <strong>Billede:</strong>
                                                                <div className="image-wrapper">
                                                                    <div className="selected-image-container">
                                                                        <div
                                                                            className={`image-button ${editMode === sign.documentId ? 'edit-mode' : ''}`}
                                                                            onClick={editMode === sign.documentId ? () => openModal(sign.documentId) : undefined}
                                                                            style={{ cursor: editMode === sign.documentId ? 'pointer' : 'default' }}
                                                                        >
                                                                            <img
                                                                                src={imageUrls.find(img => img.id === (signChanges[sign.documentId]?.image !== undefined ? signChanges[sign.documentId]?.image : sign.image))?.src}
                                                                                alt={sign.title}
                                                                                className="expanded-sign-image"
                                                                            />
                                                                            {editMode === sign.documentId && (
                                                                                <div className="image-overlay">Ændre billede</div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* Action Buttons */}
                                                            <div className="button-actions">
                                                                {editMode === sign.documentId ? (
                                                                    <>
                                                                        <button
                                                                            className="icon-button save-element-button"
                                                                            onClick={() => handleSaveSign(sign.documentId)}
                                                                            data-tooltip="Gem ændringer"
                                                                        >
                                                                            <i className="bi bi-floppy"></i>
                                                                        </button>
                                                                        <button
                                                                            className="icon-button"
                                                                            onClick={() => handleCancelEdit(sign.documentId)} // Call handleCancelEdit here
                                                                            data-tooltip="Annuller"
                                                                        >
                                                                            <i className="bi bi-arrow-return-left"></i>
                                                                        </button>
                                                                    </>
                                                                ) : (
                                                                    <button
                                                                        className="icon-button edit-element-button"
                                                                        onClick={() => handleEditToggle(sign.documentId)}
                                                                        data-tooltip="Rediger"
                                                                    >
                                                                        <i className="bi bi-pencil"></i>
                                                                    </button>
                                                                )}
                                                            </div>

                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </>
            )}
            {/* Modal for Image Selection */}
            {showModal && (
                <div className="infosign-modal-overlay" onClick={closeModal}>
                    <div className="infosign-modal-content" onClick={e => e.stopPropagation()}>
                        <div className="infosign-modal-header">
                            <h3>Vælg et billede</h3>
                            <button className="icon-button" onClick={closeModal} data-tooltip="annuller">
                                <i className="bi bi-x-lg"></i>
                            </button>
                        </div>
                        <ImageSelector
                            onSelectImage={handleSelectImage}
                            selectedImageId={selectedImage}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}
