import React, { useEffect, useState } from 'react';
import { collection, onSnapshot, doc, updateDoc, Timestamp, arrayUnion, arrayRemove } from "firebase/firestore";
import { db } from '../FirebaseConfig';
import { getStorage, ref, getDownloadURL, uploadBytes, deleteObject } from 'firebase/storage';
import './css/ComponentStyling.css';
import { getChapterIcon } from './ImageUrls';
import { collectableImages } from './DefaultCollectablesImageUrls';
import { storage } from '../FirebaseConfig';

export default function CollectablesDataTable() {
    const [collectablesData, setCollectablesData] = useState({});
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [expandedChapter, setExpandedChapter] = useState("All");
    const [expandedCollectables, setExpandedCollectables] = useState({});
    const [collectableChanges, setCollectableChanges] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCollectable, setSelectedCollectable] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [newImage, setNewImage] = useState(null);
    const [showSecondModal, setShowSecondModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [storageImages, setStorageImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImagePreview, setSelectedImagePreview] = useState(null);

    useEffect(() => {
        const unsubscribe = onSnapshot(
            collection(db, 'CollectablesData'),
            (snapshot) => {
                const updatedCollectablesData = {};
                snapshot.forEach(doc => {
                    const data = doc.data();
                    const documentId = doc.id;
                    const chapter = data.Chapter;

                    if (chapter >= 2 && chapter <= 7) {
                        if (!updatedCollectablesData[chapter]) {
                            updatedCollectablesData[chapter] = [];
                        }

                        updatedCollectablesData[chapter].push({
                            documentId,
                            chapter,
                            danishName: data.DanishName || '',
                            Image: data.Image || '',
                            Name: data.Name || '',
                        });
                    }
                });

                setCollectablesData(updatedCollectablesData);
                setIsDataLoaded(true);
            },
            (error) => {
                console.error('Firestore error:', error);
            }
        );

        return () => unsubscribe();
    }, []);

    const fetchStorageImages = () => {
        const storageDocRef = doc(db, 'Storage', 'Images'); // Antag at dokumentet hedder 'Images'
        onSnapshot(storageDocRef, (snapshot) => {
            const data = snapshot.data();
            const allUrls = [];

            // Saml alle URL'er fra arrays i "Collection 1" til "Collection 6"
            for (let i = 1; i <= 6; i++) {
                const collectionField = `Collection ${i}`;
                if (data[collectionField]) {
                    allUrls.push(...data[collectionField]);
                }
            }

            setStorageImages(allUrls); // Opdater state med URL'er fra Firestore
        }, (error) => {
            console.error("Fejl ved hentning af billeder fra Firestore: ", error);
        });
    };

    const handleToggleExpandChapter = (chapter) => {
        setExpandedChapter(prevChapter => prevChapter === chapter ? null : chapter);
        setExpandedCollectables({});
        setCollectableChanges({});
    };

    const handleCollectableChange = (e, docId, field) => {
        const { value } = e.target;
        setCollectablesData(prevData => {
            const updatedData = { ...prevData };

            Object.keys(updatedData).forEach(chapter => {
                updatedData[chapter] = updatedData[chapter].map(collectable =>
                    collectable.documentId === docId
                        ? { ...collectable, [field]: value }
                        : collectable
                );
            });

            return updatedData;
        });

        setCollectableChanges(prevChanges => ({
            ...prevChanges,
            [docId]: {
                ...prevChanges[docId],
                [field]: value
            }
        }));
    };

    const getDefaultImage = (Name) => {
        const image = collectableImages.find(img => img.Name === Name);
        return image ? image.src : '';
    };

    const handleImageClick = (collectable) => {
        setSelectedCollectable(collectable);
        setShowModal(true);
    };

    const handleSave = async (docId) => {
        const collectableToUpdate = getAllCollectables().find(collectable => collectable.documentId === docId);
        if (!collectableToUpdate) {
            alert("Fejl ved gemme collectable. Ugyldig collectable data.");
            return;
        }

        let imageUrl = collectableToUpdate.Image; // Beholder den nuværende billede-URL
        const chapter = collectableToUpdate.chapter; // Hent chapter-feltet for collectable

        // Bestem hvilken collection billedet skal gemmes i baseret på chapter
        const determineStorageCollection = (chapter) => {
            switch (chapter) {
                case 2:
                    return 'Collection 1';
                case 3:
                    return 'Collection 2';
                case 4:
                    return 'Collection 3';
                case 5:
                    return 'Collection 4';
                case 6:
                    return 'Collection 5';
                case 7:
                    return 'Collection 6';
                default:
                    return 'Collection 1';
            }
        };

        // Hvis der er valgt et nyt billede, uploader vi det
        if (newImage) {
            const storageCollection = determineStorageCollection(chapter); // Bestem hvilken eksisterende mappe der skal bruges
            const storageRef = ref(storage, `Collectables/${storageCollection}/${newImage.name}`);

            try {
                await uploadBytes(storageRef, newImage); // Upload til eksisterende mappe
                imageUrl = await getDownloadURL(storageRef); // Få URL til det uploadede billede
            } catch (error) {
                console.error("Fejl ved upload af billede: ", error);
                alert("Fejl ved upload af billede.");
                return; // Afbryd funktionen, hvis billeduploaden fejler
            }
        } else if (selectedImage) {
            // Use the selected image URL if it exists
            imageUrl = selectedImage;
        }

        // Gem ændringerne i Firestore
        const docRef = doc(db, 'CollectablesData', docId);
        try {
            await updateDoc(docRef, {
                DanishName: collectableChanges[docId]?.danishName || collectableToUpdate.danishName,
                Image: imageUrl, // Brug enten det nye eller det gamle billede
                LastUpdated: Timestamp.now()
            });

            // Reset newImage and selectedImage after uploading
            setNewImage(null); // This line resets the newImage state
            setSelectedImage(null); // Reset the selectedImage state

            // Opdater LastUpdated i GlobalTimeStamp
            const globalTimestampRef = doc(db, 'GlobalTimeStamp', 'ServerTimeStamp');
            await updateDoc(globalTimestampRef, {
                LastUpdated: Timestamp.now()
            });

            // Kun tilføj URL'en til Storage-collectionen, hvis der er valgt eller uploadet et billede og URL'en ikke er tom
            if (imageUrl) {
                const storageDocRef = doc(db, 'Storage', 'Images');
                await updateDoc(storageDocRef, {
                    [`${determineStorageCollection(chapter)}`]: arrayUnion(imageUrl) // Tilføj billedet til den relevante collection
                });
            }

            setCollectableChanges(prev => ({
                ...prev,
                [docId]: {}
            }));

            alert("Collectable opdateret succesfuldt!");
            setShowModal(false);
            setSelectedImagePreview(null);

        } catch (error) {
            console.error("Fejl ved opdatering af collectable: ", error);
            alert("Fejl ved opdatering af collectable.");
        }
    };

    const handleDeleteImageFromGallery = async (imageUrl) => {
        const storageRef = ref(storage, imageUrl); // Referencen til billedet i Firebase Storage
        const confirmDelete = window.confirm("Er du sikker på, at du vil slette dette billede?");

        if (!confirmDelete) {
            return; // Stop udførelsen, hvis brugeren annullerede
        }

        try {
            await deleteObject(storageRef); // Slet billedet fra Firebase Storage
            alert('Billede slettet fra galleri!');

            // Opdater storageImages state efter sletning
            setStorageImages(prevImages => prevImages.filter(imgUrl => imgUrl !== imageUrl));

            // Find det collectable, der indeholder billedet
            const collectables = await getAllCollectables(); // Antag, at denne funktion henter alle collectables
            const collectableToUpdate = collectables.find(collectable => collectable.Image === imageUrl);

            if (collectableToUpdate) {
                const docId = collectableToUpdate.documentId; // Få dokument ID
                const docRef = doc(db, 'CollectablesData', docId); // Referencer til collectable dokumentet

                // Fjern URL fra Firestore
                await updateDoc(docRef, {
                    Image: '', // Sæt til tom streng eller brug arrayRemove hvis det er et array

                });
            }

            // Fjern URL fra Firestore (hvis det er nødvendigt)
            const storageDocRef = doc(db, 'Storage', 'Images');
            await updateDoc(storageDocRef, {
                'Collection 1': arrayRemove(imageUrl) // Fjern URL fra Firestore, opdatér baseret på din struktur
            });

        } catch (error) {
            console.error('Fejl ved sletning af billede: ', error);
            alert('Fejl ved sletning af billede.');
        }
    };

    const toggleEditing = () => {
        setIsEditing(prev => !prev);
    };

    const handleImageSelect = (url) => {
        if (selectedCollectable) {
            // Store the selected image URL in local state
            setSelectedImage(url); // Update the local state with the selected image

            setSelectedImagePreview(url); // Set the selected image URL for preview

            // Optionally, you can set it in your collectableChanges if you're using that
            const docId = selectedCollectable.documentId;
            setCollectableChanges(prevChanges => ({
                ...prevChanges,
                [docId]: {
                    ...prevChanges[docId],
                    Image: url // Update the Image field in local state
                }
            }));

            // Close the modal
            setShowSecondModal(false);

        }
    };

    const getAllCollectables = () => {
        return Object.keys(collectablesData).reduce((acc, chapter) => {
            return [...acc, ...collectablesData[chapter]];
        }, []);
    };

    const filterCollectablesBySearchTerm = (collectables) => {
        if (!searchTerm) return collectables;

        return collectables.filter(collectable =>
            collectable.danishName.toLowerCase().includes(searchTerm.toLowerCase())
        );
    };

    const collectablesToShow = expandedChapter === "All"
        ? filterCollectablesBySearchTerm(getAllCollectables())
        : filterCollectablesBySearchTerm(collectablesData[expandedChapter] || []);

    return (
        <div className="element-data-container">
            {!isDataLoaded ? (
                <p>Loading...</p>
            ) : (
                <>
                    <div className="category-button-container">
                        <div className="category-button-grid">
                            <button
                                className={`category-button ${expandedChapter === "All" ? 'active' : ''}`}
                                onClick={() => handleToggleExpandChapter("All")}
                            >
                                <img
                                    src={getChapterIcon("All")}
                                    alt="All Chapters Icon"
                                    className="category-icon"
                                />
                                Alle Kapitler
                            </button>
                            {Object.keys(collectablesData).map((chapter) => (
                                <button
                                    key={chapter}
                                    className={`category-button ${expandedChapter === chapter ? 'active' : ''}`}
                                    onClick={() => handleToggleExpandChapter(chapter)}
                                >
                                    <img
                                        src={getChapterIcon(chapter)}
                                        alt={`Kapitel ${chapter} Icon`}
                                        className="category-icon"
                                    />
                                    {`Kapitel ${chapter}`}
                                </button>
                            ))}
                        </div>
                    </div>
                    {expandedChapter && (
                        <div className="category-details">
                            {/* <div className="search-container">
                                <input
                                    type="text"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    placeholder="Søg i samlinger..."
                                    className="search-input"
                                />
                            </div> */}
                            <div className="collectable-grid">
                                {collectablesToShow.map((collectable) => (
                                    <div className="collectable-card" key={collectable.documentId} onClick={() => handleImageClick(collectable)}>
                                        <div className="image-container">
                                            <img
                                                src={collectable.Image || getDefaultImage(collectable.Name)}
                                                alt={collectable.danishName}
                                                className="collectable-image"
                                            />
                                            <div className="overlay">
                                                <h3>{collectable.danishName}</h3>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </>
            )}
            {showModal && (
                <div className="collectable-modal-overlay" onClick={() => setShowModal(false)}>
                    <div className="minigame-modal-content" onClick={e => e.stopPropagation()}>
                        <div className="minigame-modal-header">
                            <h2>Rediger Samleobjekt</h2>
                            <button
                                className="icon-button"
                                onClick={() => {
                                    setSelectedImagePreview(null); // Reset the preview
                                    setSelectedImage(null);
                                    setShowModal(false);
                                }}
                                
                                data-tooltip="Luk"
                            >
                                <i className="bi bi-x-lg"></i>
                            </button>
                        </div>
                        <div className="minigame-modal-body">
                            <label className="bold-label">
                                Dansk Navn:
                                <input
                                    type="text"
                                    value={collectableChanges[selectedCollectable.documentId]?.danishName || selectedCollectable.danishName}
                                    onChange={(e) => handleCollectableChange(e, selectedCollectable.documentId, 'danishName')}
                                    className="collectabe-modal-input"
                                />
                            </label>
                            <div className="upload-container">
                                <label className="upload-label bold-label">
                                    Upload nyt billede:
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) => setNewImage(e.target.files[0])}
                                    />
                                </label>
                                <div className="gallery-container">
                                    <label className="upload-label bold-label">
                                        Vælg billede fra galleri:
                                    </label>
                                    <div style={{ display: 'flex', alignItems: 'center' }}> {/* Flex container */}
                                        <button
                                            className="icon-button gallery-button"
                                            onClick={async () => {
                                                await fetchStorageImages();
                                                setIsEditing(false);
                                                setShowSecondModal(true);
                                            }}
                                            data-tooltip="Vælg fra galleri"
                                        >
                                            <i className="bi bi-image"></i>
                                        </button>
                                        {selectedImagePreview && (
                                            <img
                                                src={selectedImagePreview}
                                                alt="Selected Preview"
                                                style={{ width: '50px', border: '1px solid black', marginLeft: '10px' }} // Add margin for spacing
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="button-container">

                            <button
                                className="icon-button save-element-button"
                                onClick={() => handleSave(selectedCollectable.documentId)}
                                data-tooltip="Gem ændringer"
                            >
                                <i className="bi bi-floppy"></i>
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {showSecondModal && (
                <div className="collectable-modal-overlay" onClick={() => setShowSecondModal(false)}>
                    <div className="secondModal-content" onClick={e => e.stopPropagation()} >


                        <div className="button-group-container">
                            <h2>Vælg fra galleri</h2>
                            <div className="button-group">
                                <button className="icon-button edit-element-button toggle-editing "
                                    onClick={toggleEditing}
                                    data-tooltip="Rediger"
                                >
                                    <i className="bi bi-pencil"></i>
                                </button>
                                <button
                                    className="icon-button "
                                    onClick={() => setShowSecondModal(false)}
                                    data-tooltip="Luk"
                                >
                                    <i className="bi bi-x-lg"></i>
                                </button>
                            </div>
                        </div>
                        <div className='gallery-grid-wrapper'>
                        <div className="gallery-grid">
                            {storageImages.map((url, index) => (
                                <div key={index} className="gallery-image-container">
                                    <img
                                        src={url}
                                        alt={`image-${index}`}
                                        className="gallery-image"
                                        onClick={!isEditing ? () => handleImageSelect(url) : undefined}
                                    />
                                    {isEditing && (
                                        <button
                                            className="delete-image-button"
                                            onClick={() => handleDeleteImageFromGallery(url)}
                                            data-tooltip="Slet billede"
                                        >
                                            <i className="bi bi-trash"></i>
                                        </button>
                                    )}
                                </div>
                            ))}
                        </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
