import image0 from '../images/InformationSignIcons/recycleBorder.png';
import image1 from '../images/InformationSignIcons/UI_Graphic_Resource_Wood-out.png';
import image2 from '../images/InformationSignIcons/UI_Graphic_Resource_Iron-outline.png';
import image3 from '../images/InformationSignIcons/tyre-outline.png';
import image4 from '../images/InformationSignIcons/icons8-eastwood-showcase-glass.png';
import image5 from '../images/InformationSignIcons/water-bottle (2).png';
import image6 from '../images/InformationSignIcons/icons8-fabric.png';
import image7 from '../images/InformationSignIcons/099_apple.png';
import image8 from '../images/InformationSignIcons/laptop-screen-1.png';
import image9 from '../images/InformationSignIcons/097_battery-outline.png';
import image10 from '../images/InformationSignIcons/031_clay brick.png';
import image11 from '../images/InformationSignIcons/flaticon-bookshelf.png';
import image12 from '../images/InformationSignIcons/icon_Food_Pizza.png';

export const imageUrls = [
  { src: image0, id: 0 },
  { src: image1, id: 1 },
  { src: image2, id: 2 },
  { src: image3, id: 3 },
  { src: image4, id: 4 },
  { src: image5, id: 5 },
  { src: image6, id: 6 },
  { src: image7, id: 7 },
  { src: image8, id: 8 },
  { src: image9, id: 9 },
  { src: image10, id: 10 },
  { src: image11, id: 11 },
  { src: image12, id: 12 },

];

// Define a mapping of image names to their corresponding images
export const imageNameMapping = {
  Recycle: image0,
  Wood: image1,
  Iron: image2,
  Rubber: image3,
  Glass: image4,
  WaterBottle: image5,
  Fabric: image6,
  Apple: image7,
  Laptop: image8,
  Battery: image9,
  ClayBrick: image10,
  Bookshelf: image11,
  Pizza: image12,
};

// Mapping of imageicons to each chapter
export const getChapterIcon = (chapter) => {
  // If chapter is not a number or a valid string representation of a number, default to null
  const chapterNumber = chapter === "All" || !chapter ? 1 : parseInt(chapter, 10);
  
  switch (chapterNumber) {
    case 1:
      return imageNameMapping['Recycle']; // Default image or 'Recycle' as placeholder
    case 2:
      return imageNameMapping['Wood'];
    case 3:
      return imageNameMapping['Iron'];
    case 4:
      return imageNameMapping['Rubber'];
    case 5:
      return imageNameMapping['Glass'];
    case 6:
      return imageNameMapping['WaterBottle'];
    case 7:
      return imageNameMapping['Fabric'];
    default:
      return imageNameMapping['Recycle']; // Return an empty string or a default image if the chapter number is not mapped
  }
};
