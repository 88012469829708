import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './components/Website/SiteComponents/app.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './components/Website/HomePage';
import CMS from './components/ContentManagerSystem/ContentManager';
import Login from './components/login/Login';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from './components/ContentManagerSystem/FirebaseConfig'; // Import auth and db from FirebaseConfig
import { doc, getDoc } from 'firebase/firestore'; // Import Firestore functions

function App() {
  const [user, setUser] = React.useState(null);
  const [userRole, setUserRole] = React.useState(null); // Add state to track user role

  // Monitor authentication status
  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);

        // Fetch user role from Firestore or another source
        const userDoc = await getDoc(doc(db, 'Users', user.email));
        if (userDoc.exists()) {
          setUserRole(userDoc.data().Role); // Assume role is stored as "Role" in user document
        }
      } else {
        setUser(null);
        setUserRole(null);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/CMS"
          element={user ? <CMS userRole={userRole} /> : <Login />} // Pass userRole to CMS component
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

