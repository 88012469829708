import 'bootstrap/dist/css/bootstrap.min.css';
import { collection, onSnapshot, doc, updateDoc, getDocs, getDoc, deleteDoc, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from 'react';
import { db } from '../FirebaseConfig';
import '../CMSComponents/css/ComponentStyling.css';
import ItemDropdown from './ItemDropdown.js';
import { Form } from 'react-bootstrap';
import { getChapterIcon } from './ImageUrls.js';
import { setGlobalUpdated } from './Functions/GlobalUpdated.js';
//import CreateMission from './CreateMission.js'; BRUGES IKKE DA CREATE ER DEAKTIVERET


export default function ChapterList() {
    const [chapterData, setChapterData] = useState([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [expandedChapter, setExpandedChapter] = useState("Chapter1");
    const [missionsData, setMissionsData] = useState({});
    const [showMissionsFor, setShowMissionsFor] = useState(null);
    const [selectedMission, setSelectedMission] = useState(null);
    const [selectedItem, setSelectedItem] = useState(['']);
    const [items, setItems] = useState([]); // Assuming you have a list of items
    const [quizzesData, setQuizzesData] = useState({});
    const [showQuizzesFor, setShowQuizzesFor] = useState(null);
    const [selectedQuiz, setSelectedQuiz] = useState(null);
    const [quizChanges, setQuizChanges] = useState({});
    const [expandedMission, setExpandedMission] = useState(null);
    const [expandedQuiz, setExpandedQuiz] = useState(null);
    const [activeView, setActiveView] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    // const [showCreateMission, setShowCreateMission] = useState(true); BRUGES IKKE DA CREATE MISSION ER DEAKTIVERET
    //const [selectedChapter, setSelectedChapter] = useState(null);     BRUGES IKKE DA CREATE MISSION ER DEAKTIVERET
    const [isEditingChapter, setIsEditingChapter] = useState(false);
    const [isEditingMission, setIsEditingMission] = useState(false);
    const [isEditingQuiz, setIsEditingQuiz] = useState(false);
    const [originalMission, setOriginalMission] = useState(null);


    const chapterNameData = {
        Chapter1: { name: "Kapitel 1" },
        Chapter2: { name: "Kapitel 2" },
        Chapter3: { name: "Kapitel 3" },
        Chapter4: { name: "Kapitel 4" },
        Chapter5: { name: "Kapitel 5" },
        Chapter6: { name: "Kapitel 6" },
        Chapter7: { name: "Kapitel 7" },
        ChapterEnd: { name: "Kapitel 8" }
    };

    useEffect(() => {
        // Fetch items for dropdown
        const fetchItems = async () => {
            try {
                const itemsCollection = collection(db, 'ItemNames'); // Adjust collection name if needed
                const itemsSnapshot = await getDocs(itemsCollection);
                const itemsList = itemsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setItems(Object.entries(itemsList));
            } catch (error) {
                console.error("Error fetching items: ", error);
            }
        };

        fetchItems();

        // Check cache
        const cachedChapterData = localStorage.getItem('chapterData');
        const cachedTimestamp = localStorage.getItem('chapterDataTimestamp');

        if (cachedChapterData && cachedTimestamp) {
            const parsedData = JSON.parse(cachedChapterData);
            const lastFetchedTime = new Date(cachedTimestamp);
            const now = new Date();
            const cacheDuration = 60 * 60 * 1000; // 1 hour

            if (now - lastFetchedTime < cacheDuration) {
                setChapterData(parsedData);
                setIsDataLoaded(true);
                return;
            }
        }

        // Firestore setup
        const chapterCollection = collection(db, 'Chapters');

        const unsubscribeChapters = onSnapshot(chapterCollection, (snapshot) => {
            const updatedChapterData = [];
            snapshot.forEach(doc => {
                const data = doc.data();
                updatedChapterData.push({
                    documentId: doc.id,
                    chapterTitle: data.Title || '',
                    chapterDescription: data.Description || '',
                });
            });

            setChapterData(updatedChapterData);
            setIsDataLoaded(true);
            localStorage.setItem('chapterData', JSON.stringify(updatedChapterData));
            localStorage.setItem('chapterDataTimestamp', new Date().toISOString());
        });

        // Fetch quizzes
        const quizCollection = collection(db, 'Quiz');
        const unsubscribeQuizzes = onSnapshot(quizCollection, (snapshot) => {
            const updatedQuizzesData = {};
            snapshot.forEach(doc => {
                const data = doc.data();
                const chapterId = data.chapterId;
                const questions = data.questions;
                if (!updatedQuizzesData[chapterId]) {
                    updatedQuizzesData[chapterId] = [];
                }
                updatedQuizzesData[chapterId].push({
                    id: doc.id,
                    questions: doc.questions.Text,
                    ...data
                });
            });
            setQuizzesData(updatedQuizzesData);
        });

        return () => {
            unsubscribeChapters();
            unsubscribeQuizzes();
        };
    }, []);

    // Handle chapter changes
    const handleChapterChange = (e, docId, field) => {
        const { value } = e.target;
        setChapterData(prevData =>
            prevData.map(chapter =>
                chapter.documentId === docId
                    ? { ...chapter, [field]: value }
                    : chapter
            )
        );
    };

    // Save chapter changes
    const handleSaveChapter = async (docId) => {
        const chapterToUpdate = chapterData.find(chapter => chapter.documentId === docId);

        if (!chapterToUpdate) {
            console.error("Invalid chapter data.");
            alert("Kunne ikke gemme kapitel. Ugyldige kapiteldata.");
            return;
        }

        const docRef = doc(db, 'Chapters', docId);
        try {
            await updateDoc(docRef, {
                Title: chapterToUpdate.chapterTitle,
                Description: chapterToUpdate.chapterDescription,
                LastUpdated: new Date()
            });

            // Calls Custom funktion to set gloabal last updated
            await setGlobalUpdated();

            alert("Kapitel opdateret med succes!");

        } catch (error) {
            console.error("Error updating chapter: ", error);
            alert("Kunne ikke opdatere kapitel.");
        }
    };

    // Toggle chapter expansion
    const handleToggleExpand = (docId) => {
        setExpandedChapter(prevChapter => {
            if (prevChapter !== docId) {
                // Reset all view-related states when switching categories (chapters)
                setShowMissionsFor(null);
                setShowQuizzesFor(null);
                setSelectedMission(null); // Clear selected mission if any
                setSelectedQuiz(null);    // Clear selected quiz if any
                setActiveView(null);      // Reset activeView to clear active button state
                setExpandedMission(null);
                setExpandedQuiz(null);
            }
            return prevChapter === docId ? null : docId; // Toggle expand/collapse
        });
        console.log(expandedMission)
    };

    const handleCancel = () => {
        // Reset editing state to false
        setIsEditingMission(false);
        setSelectedMission(originalMission);
        // Keep the selected mission as is, no need to set it to null
        // This allows users to see the current values without entering editing mode again
    };

    const handleAddItem = () => {
        setSelectedMission(prevMission => ({
            ...prevMission,
            ItemNames: [...(prevMission.ItemNames || []), '']
        }));
    };

    const handleRemoveItem = (index) => {
        setSelectedMission(prevMission => {
            const updatedItemNames = [...(prevMission.ItemNames || [])];
            updatedItemNames.splice(index, 1);
            return {
                ...prevMission,
                ItemNames: updatedItemNames
            };
        });
    };

    const handleItemNameChange = (index, newItem) => {
        setSelectedMission(prevMission => {
            const updatedItemNames = [...(prevMission.ItemNames || [])];
            updatedItemNames[index] = newItem;
            return {
                ...prevMission,
                ItemNames: updatedItemNames
            };
        });
    };

    // Show missions for a chapter
    const handleShowMissions = async (docId) => {
        if (showMissionsFor === docId) {
            // Hide missions if they are already displayed for the current chapter
            setShowMissionsFor(null);
            setSelectedMission(null); // Clear selected mission when hiding missions
            setExpandedMission(null); // Collapse expanded mission
            setActiveView(null); // Deselect the active view
            return;
        }

        try {
            // Hide quizzes if they are currently displayed
            if (showQuizzesFor) {
                setShowQuizzesFor(null);
                setSelectedQuiz(null);
                setExpandedQuiz(null); // Collapse expanded quiz
            }

            // Fetch and display missions for the selected chapter
            const missionsCollection = collection(db, 'Chapters', docId, 'Missions');
            const querySnapshot = await getDocs(missionsCollection);
            const missions = querySnapshot.docs.map(doc => ({
                missionId: doc.id,
                ...doc.data()
            }));
            setMissionsData(prev => ({
                ...prev,
                [docId]: missions
            }));
            setShowMissionsFor(docId);
            setActiveView('missions'); // Set the active view to missions
        } catch (error) {
            console.error("Error fetching missions: ", error);
        }
    };



    // Handle mission click
    const handleMissionClick = (missionId) => {
        if (expandedMission === missionId) {
            // Collapse the mission details if it's already expanded
            setExpandedMission(null);
            setSelectedMission(null);
        } else {
            const selected = missionsData[expandedChapter]?.find(m => m.missionId === missionId);
            setExpandedMission(missionId);
            setSelectedMission(selected);
            setOriginalMission(selected);
        }
    };


    // Handle mission input changes
    const handleMissionChange = (e, field) => {
        const { value, type, checked } = e.target;

        // Convert value to integer if field is one of the integer fields
        const fieldsToConvert = ['PrefGoalValue', 'MoneyReward', 'XpReward', 'level'];
        const newValue = fieldsToConvert.includes(field) ? parseInt(value, 10) : (type === 'checkbox' ? checked : value);

        setSelectedMission(prevMission => ({
            ...prevMission,
            [field]: newValue
        }));
    };

    const handleDeleteMission = async (docId, missionId) => {
        if (!window.confirm("Are you sure you want to delete this mission? This action cannot be undone.")) {
            return;
        }

        try {
            // 1. Find the mission to be deleted
            const missionToDelete = missionsData[docId].find(m => m.missionId === missionId);
            if (!missionToDelete) {
                alert("Mission ikke fundet.");
                return;
            }

            const deletedMissionNumber = parseInt(missionToDelete.missionId.split('-')[1], 10);

            // 2. Delete the mission from Firestore
            const missionRef = doc(db, 'Chapters', docId, 'Missions', missionId);
            await deleteDoc(missionRef);

            // 3. Prepare updated missions
            const updatedMissions = missionsData[docId].filter(m => m.missionId !== missionId);

            // Loop through the remaining missions and update their IDs and Names
            const missionsToUpdate = updatedMissions.filter(m => {
                const missionNumber = parseInt(m.missionId.split('-')[1], 10);
                return missionNumber > deletedMissionNumber; // Get all missions with a greater number
            });

            // Array to hold the old mission IDs to be deleted
            const oldMissionIdsToDelete = [];

            // Array to hold the new missions to be created
            const newMissions = [];

            for (const mission of missionsToUpdate) {
                const currentMissionId = mission.missionId;
                const missionParts = currentMissionId.split('-');
                const currentMissionNumber = parseInt(missionParts[1], 10);

                // Update MissionId (decrementing the number)
                const updatedMissionId = `C${missionParts[0].slice(1)}-${currentMissionNumber - 1}-${missionParts.slice(2).join('-')}`;

                // Update Name
                const currentNameParts = mission.Name.split(' ');
                const currentNameNumber = parseInt(currentNameParts[0].split('.')[1], 10);
                const updatedNameSecondNumber = currentNameNumber - 1; // decrement name number
                const updatedMissionName = `${currentNameParts[0].split('.')[0]}.${updatedNameSecondNumber} ${currentNameParts.slice(1).join(' ')}`;

                // Prepare the new mission object to create
                newMissions.push({
                    ...mission,
                    MissionId: updatedMissionId, // Ensure MissionId is set here
                    Name: updatedMissionName
                });

                // Add the old mission ID to the deletion list
                oldMissionIdsToDelete.push(currentMissionId);
            }

            // 4. Delete old missions from Firestore
            for (const oldMissionId of oldMissionIdsToDelete) {
                const oldMissionRef = doc(db, 'Chapters', docId, 'Missions', oldMissionId);
                await deleteDoc(oldMissionRef);
            }

            // 5. Create new missions in Firestore with the updated IDs and Names
            for (const newMission of newMissions) {
                const newMissionRef = doc(db, 'Chapters', docId, 'Missions', newMission.MissionId);
                await setDoc(newMissionRef, {
                    Chapter: parseInt(newMission.MissionId.split('-')[0].slice(1)), // Store chapter number as a number
                    Description: newMission.Description,
                    Goals: newMission.Goals,
                    IsNewMission: newMission.IsNewMission,
                    ItemNames: newMission.ItemNames,
                    LastUpdated: new Date(),
                    MissionType: newMission.MissionType,
                    MoneyReward: newMission.MoneyReward,
                    Name: newMission.Name,
                    PrefGoalValue: newMission.PrefGoalValue,
                    PrefName: newMission.PrefName,
                    SpecificItems: newMission.SpecificItems,
                    XpReward: newMission.XpReward,
                    level: newMission.level, // Maintain level as is
                    MissionId: newMission.MissionId // Ensure MissionId is set here
                });
            }

            // Set global timestamp after updating all missions
            await setGlobalUpdated();

            // 6. Update local state to reflect the new missions
            setMissionsData(prevData => ({
                ...prevData,
                [docId]: [...updatedMissions.filter(m => m.missionId < missionId), ...newMissions] // Merge old missions and newly created ones
            }));

            alert('Mission slettet og opdateret med succes.');
        } catch (error) {
            console.error("Error deleting mission: ", error);
            alert("Kunne ikke slette mission. Fejldetaljer: " + error.message);
        }
    };



    // Save mission changes
    const handleSaveMission = async (docId, missionId) => {
        // Find the mission to update based on the missionId
        const missionToUpdate = missionsData[docId]?.find(mission => mission.missionId === missionId);

        if (!missionToUpdate) {
            console.error("Invalid Mission data.");
            alert("Kunne ikke gemme mission. Ugyldige missionsdata.");
            return;
        }

        const updatedMission = {
            ...selectedMission,
            ItemNames: Array.isArray(selectedMission.ItemNames) ? selectedMission.ItemNames : [selectedMission.ItemNames],
            PrefGoalValue: Number.isInteger(selectedMission.PrefGoalValue) ? selectedMission.PrefGoalValue : parseInt(selectedMission.PrefGoalValue, 10),
            MoneyReward: Number.isInteger(selectedMission.MoneyReward) ? selectedMission.MoneyReward : parseInt(selectedMission.MoneyReward, 10),
            XpReward: Number.isInteger(selectedMission.XpReward) ? selectedMission.XpReward : parseInt(selectedMission.XpReward, 10),
            level: Number.isInteger(selectedMission.level) ? selectedMission.level : parseInt(selectedMission.level, 10),
            LastUpdated: new Date()
        };

        // Reference to the Firestore document
        const docRef = doc(db, 'Chapters', docId, 'Missions', missionId);

        try {
            // Check if the document exists before trying to update
            const docSnapshot = await getDoc(docRef);
            if (!docSnapshot.exists()) {
                console.error(`Document does not exist at path: Chapters/${docId}/Missions/${missionId}`);
                alert(`Dokumentet findes ikke på stien: Chapters/${docId}/Missions/${missionId}`);
                return;
            }

            // Update the document in Firestore
            await updateDoc(docRef, updatedMission);  // Save the updated mission including LastUpdated

            // Calls Custom function to set global last updated
            await setGlobalUpdated();

            alert("Mission opdateret med succes!");

            // Clear selected mission and refresh data
            setIsEditingMission(false);
            setSelectedMission(null);
            setSelectedItem(''); // Clear selected item after saving
            handleShowMissions(docId); // Use the same method to refresh the missions

        } catch (error) {
            console.error("Error updating mission: ", error);
            alert("Kunne ikke opdatere mission. Tjek venligst konsollen for detaljer.");
        }
    };

    const handleShowQuizzes = async (docId) => {
        if (showQuizzesFor === docId) {
            // Hide quizzes if they are already displayed for the current chapter
            setShowQuizzesFor(null);
            setSelectedQuiz(null); // Clear selected quiz when hiding quizzes
            setExpandedQuiz(null); // Collapse expanded quiz
            setActiveView(null); // Deselect the active view
            return;
        }

        try {
            // Hide missions if they are currently displayed
            if (showMissionsFor) {
                setShowMissionsFor(null);
                setSelectedMission(null);
                setExpandedMission(null); // Collapse expanded mission
            }

            // Fetch and display quizzes for the selected chapter
            const quizzesCollection = collection(db, 'Chapters', docId, 'Quiz');
            const querySnapshot = await getDocs(quizzesCollection);
            const quizzes = await Promise.all(querySnapshot.docs.map(async doc => {
                const quizData = doc.data();
                const questionsCollection = collection(db, 'Chapters', docId, 'Quiz', doc.id, 'Questions');
                const questionsSnapshot = await getDocs(questionsCollection);
                const questions = questionsSnapshot.docs.map(questionDoc => ({
                    id: questionDoc.id,
                    ...questionDoc.data()
                }));
                return {
                    quizId: doc.id,
                    questions,  // Store the fetched questions
                    ...quizData
                };
            }));

            setQuizzesData(prev => ({
                ...prev,
                [docId]: quizzes
            }));
            setShowQuizzesFor(docId);
            setActiveView('quizzes'); // Set the active view to quizzes
        } catch (error) {
            console.error("Error fetching quizzes: ", error);
        }
    };

    const handleQuizClick = (quizId) => {
        if (expandedQuiz === quizId) {
            // Collapse the quiz details if it's already expanded
            setExpandedQuiz(null);
            setSelectedQuiz(null);
        } else {
            // Expand the new quiz details
            setExpandedQuiz(quizId);
            setSelectedQuiz(quizzesData[expandedChapter]?.find(q => q.quizId === quizId));
        }
    };

    const handleQuizChange = (e, docId, quizId, questionId, field) => {
        const { value } = e.target;
        setQuizzesData(prevData => ({
            ...prevData,
            [docId]: (prevData[docId] || []).map(quiz =>
                quiz.quizId === quizId
                    ? {
                        ...quiz,
                        questions: (quiz.questions || []).map(question =>
                            question.QuestionID === questionId
                                ? { ...question, [field]: value }
                                : question
                        )
                    }
                    : quiz
            )
        }));
    };

    const handleSaveQuiz = async (docId, quizId) => {
        const quizToUpdate = quizzesData[docId]?.find(quiz => quiz.quizId === quizId);
        if (!quizToUpdate) {
            console.error("Invalid Quiz data.");
            alert("Kunne ikke gemme quiz. Ugyldige quizdata.");
            return;
        }

        const docRef = doc(db, 'Chapters', docId, 'Quiz', quizId);

        try {
            const updatedQuestions = quizToUpdate.questions.map(question => {
                const changes = quizChanges[docId]?.[quizId]?.questions.find(q => q.QuestionID === question.QuestionID) || {};
                return {
                    ...question,
                    ...changes
                };
            });

            await updateDoc(docRef, {
                questions: updatedQuestions,
                LastUpdated: new Date()
            });

            // Calls Custom funktion to set gloabal last updated
            await setGlobalUpdated();

            alert("Quiz opdateret med succes!");

            setIsEditingQuiz(false);

            // Refetch quizzes data to ensure we have the latest data
            handleShowQuizzes(docId);
        } catch (error) {
            console.error("Error updating quiz: ", error);
            alert("Kunne ikke opdatere quiz. Fejldetaljer: " + error.message);
        }
    };

    const handleAddChoice = (docId, quizId, questionId) => {
        setQuizzesData(prevData => ({
            ...prevData,
            [docId]: (prevData[docId] || []).map(quiz =>
                quiz.quizId === quizId
                    ? {
                        ...quiz,
                        questions: (quiz.questions || []).map(question =>
                            question.QuestionID === questionId
                                ? {
                                    ...question,
                                    Choices: [...(question.Choices || []), '']
                                }
                                : question
                        )
                    }
                    : quiz
            )
        }));
    };

    const handleRemoveChoice = (docId, quizId, questionId, index) => {
        setQuizzesData(prevData => ({
            ...prevData,
            [docId]: (prevData[docId] || []).map(quiz =>
                quiz.quizId === quizId
                    ? {
                        ...quiz,
                        questions: (quiz.questions || []).map(question =>
                            question.QuestionID === questionId
                                ? {
                                    ...question,
                                    Choices: (question.Choices || []).filter((_, i) => i !== index)
                                }
                                : question
                        )
                    }
                    : quiz
            )
        }));
    };

    const handleChoiceChange = (e, docId, quizId, questionId, index) => {
        const { value } = e.target;
        setQuizzesData(prevData => ({
            ...prevData,
            [docId]: (prevData[docId] || []).map(quiz =>
                quiz.quizId === quizId
                    ? {
                        ...quiz,
                        questions: (quiz.questions || []).map(question =>
                            question.QuestionID === questionId
                                ? {
                                    ...question,
                                    Choices: (question.Choices || []).map((choice, i) =>
                                        i === index ? value : choice
                                    )
                                }
                                : question
                        )
                    }
                    : quiz
            )
        }));
    };

    const filterBySearchTerm = (results, searchTerm) => {
        if (!searchTerm) return results;

        const lowerCaseSearchTerm = searchTerm.toLowerCase();

        const containsSearchTerm = (item) => {
            if (typeof item === 'string') {
                return item.toLowerCase().includes(lowerCaseSearchTerm);
            }

            if (Array.isArray(item)) {
                // Instead of treating the entire array, filter individual elements in the array
                return item.filter(containsSearchTerm).length > 0;
            }

            if (typeof item === 'object' && item !== null) {
                // Check for search term in nested objects
                return Object.values(item).some(containsSearchTerm);
            }

            return false;
        };

        // Filter the results
        return results.map(result => {
            if (result.questions) {
                // Apply filtering on questions array for quizzes
                const filteredQuestions = result.questions.filter(question => containsSearchTerm(question));
                return { ...result, questions: filteredQuestions };
            }

            // For non-quiz results like missions, apply filter directly
            return containsSearchTerm(result) ? result : null;
        }).filter(Boolean); // Remove nulls from non-matching results
    };

    const extractNumberFromStringAsInt = (str) => {
        const match = str.match(/\d+/); // Find one or more digits
        return match ? parseInt(match[0], 10) : NaN; // Convert to integer or return NaN if no match
    };

    const chapterName = chapterNameData[expandedChapter]?.name || '';

    return (
        <div className="element-data-container">
            {!isDataLoaded ? (
                <p>Loading...</p>
            ) : (
                <>
                    <div className="category-button-container">
                        <div className="category-button-grid">
                            {chapterData.map(chapter => (
                                <button
                                    key={chapter.documentId}
                                    className={`category-button ${expandedChapter === chapter.documentId ? 'active' : ''}`}
                                    onClick={() => handleToggleExpand(chapter.documentId)}
                                >
                                    <img
                                        src={getChapterIcon(extractNumberFromStringAsInt(chapter.documentId))}
                                        alt={getChapterIcon()}
                                        className="category-icon"
                                    />
                                    {chapter.chapterTitle}
                                </button>
                            ))}
                        </div>
                    </div>
                    {expandedChapter && (
                        <div className="category-details">
                            <div className="chapter-container">
                                <h2 className="chapter-header">
                                    {expandedChapter && chapterNameData[expandedChapter]?.name
                                        ? `${chapterNameData[expandedChapter].name}: ${chapterData.find(chap => chap.documentId === expandedChapter)?.chapterTitle}`
                                        : 'Kapitel'}
                                </h2>

                                {/* Input Fields */}
                                <div className="mb-2">
                                    <label>Kapitel Titel</label>
                                    <input
                                        type="text"
                                        value={chapterData.find(chap => chap.documentId === expandedChapter)?.chapterTitle || ''}
                                        onChange={(e) => handleChapterChange(e, expandedChapter, 'chapterTitle')}
                                        className={`form-control ${!isEditingChapter ? 'input-not-editable' : ''}`}
                                        disabled={!isEditingChapter} // Disable input when not editing
                                    />
                                </div>
                                <div className="mb-2">
                                    <label>Kapitel beskrivelse</label>
                                    <textarea
                                        value={chapterData.find(chap => chap.documentId === expandedChapter)?.chapterDescription || ''}
                                        onChange={(e) => handleChapterChange(e, expandedChapter, 'chapterDescription')}
                                        className={`form-control ${!isEditingChapter ? 'input-not-editable' : ''}`}
                                        rows="3"
                                        disabled={!isEditingChapter} // Disable textarea when not editing
                                    />
                                </div>
                                {/* Edit Button */}
                                {!isEditingChapter && (
                                    <button
                                        className="icon-button edit-element-button"
                                        onClick={() => setIsEditingChapter(true)}
                                        data-tooltip="Rediger kapitel"
                                    >
                                        <i className="bi bi-pencil"></i>
                                    </button>
                                )}
                                {/* Show action buttons only in edit mode */}
                                <div className='button-actions'>
                                    {isEditingChapter && (
                                        <>
                                            <button
                                                className="icon-button save-element-button"
                                                onClick={() => {
                                                    handleSaveChapter(expandedChapter); // Call save function
                                                    setIsEditingChapter(false); // Exit edit mode
                                                }}
                                                data-tooltip="Gem kapitel"
                                            >
                                                <i className="bi bi-floppy"></i>
                                            </button>
                                            <button
                                                className="icon-button"
                                                onClick={() => setIsEditingChapter(false)} // Cancel editing
                                                data-tooltip="Annuller"
                                            >
                                                <i className="bi bi-arrow-return-left"></i>
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="data-buttons-container">
                                <button
                                    className={`data-button ${activeView === 'missions' ? 'active' : ''}`}
                                    onClick={() => handleShowMissions(expandedChapter)}
                                >
                                    Vis Mission Data
                                </button>
                                <button
                                    className={`data-button ${activeView === 'quizzes' ? 'active' : ''}`}
                                    onClick={() => handleShowQuizzes(expandedChapter)}
                                >
                                    Vis Quiz Data
                                </button>
                            </div>
                            {/* Render Missions */}
                            {showMissionsFor === expandedChapter && (
                                <div className="element-data-container">
                                    <div className="search-container">
                                        <input
                                            type="text"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            placeholder="Søg i missioner..."
                                            className="search-input"
                                        />
                                    </div>
                                    <p></p>
                                    {/* Render the CreateMission modal when showCreateMission is true } BRUGES IKKE DA CREATE OG DELETE IKKE ER SAT OP I UNITY
                                    {showCreateMission && (
                                        <div className="add-object-button-container">
                                            <CreateMission docId={expandedChapter} />
                                        </div>
                                    )*/}
                                    <table className="element-table">
                                        <tbody>
                                            {missionsData[expandedChapter] && missionsData[expandedChapter].length > 0 ? (
                                                filterBySearchTerm(missionsData[expandedChapter], searchTerm).map((mission) => (
                                                    <React.Fragment key={mission.missionId}>
                                                        <tr>
                                                            <td>
                                                                <button
                                                                    className={`full-width-button ${expandedMission === mission.missionId ? 'expanded' : ''}`}
                                                                    onClick={() => handleMissionClick(mission.missionId, expandedChapter)}
                                                                >
                                                                    {mission.Name || mission.missionId}
                                                                    <i className={`bi ${expandedMission === mission.missionId ? 'bi-chevron-double-up' : 'bi-chevron-double-down'}`}></i>
                                                                </button>
                                                            </td>
                                                        </tr>

                                                        {expandedMission === mission.missionId && selectedMission && (
                                                            <tr className="expanded-row expanded">
                                                                <td>
                                                                    <div className="edit-mission-data-container">
                                                                        {/* Full-width fields for Mission Name and Description */}
                                                                        <div className="edit-mission-field full-width">
                                                                            <label>Mission Name</label>
                                                                            <input
                                                                                type="text"
                                                                                value={selectedMission?.Name || ''}
                                                                                onChange={(e) => handleMissionChange(e, 'Name')}
                                                                                className={`form-control ${!isEditingMission ? 'input-not-editable' : ''}`}
                                                                                disabled={!isEditingMission} // Disable input when not editing
                                                                            />
                                                                        </div>

                                                                        <div className="edit-mission-field full-width">
                                                                            <label>Mission Description</label>
                                                                            <textarea
                                                                                value={selectedMission.Description || ''}
                                                                                onChange={(e) => handleMissionChange(e, 'Description')}
                                                                                className={`form-control ${!isEditingMission ? 'input-not-editable' : ''}`}
                                                                                rows="3"
                                                                                disabled={!isEditingMission} // Disable textarea when not editing
                                                                            />
                                                                        </div>
                                                                        {/* Two-column layout for remaining fields */}
                                                                        <div className="two-column-layout">
                                                                            <div className="left-column">
                                                                                <div className="edit-mission-field">
                                                                                    <label>Goals</label>
                                                                                    <input
                                                                                        type="text"
                                                                                        value={selectedMission.Goals || ''}
                                                                                        onChange={(e) => handleMissionChange(e, 'Goals')}
                                                                                        className={`form-control ${!isEditingMission ? 'input-not-editable' : ''}`}
                                                                                        disabled={!isEditingMission} // Disable input when not editing
                                                                                    />
                                                                                </div>

                                                                                <div className="edit-mission-field">
                                                                                    <label>Money Reward</label>
                                                                                    <input
                                                                                        type="number"
                                                                                        value={selectedMission.MoneyReward || ''}
                                                                                        onChange={(e) => handleMissionChange(e, 'MoneyReward')}
                                                                                        className={`form-control ${!isEditingMission ? 'input-not-editable' : ''}`}
                                                                                        onWheel={(e) => e.target.blur()} // Prevent scrolling
                                                                                        disabled={!isEditingMission} // Disable input when not editing
                                                                                    />
                                                                                </div>
                                                                                <div className="edit-mission-field">
                                                                                    <label>XP Reward</label>
                                                                                    <input
                                                                                        type="number"
                                                                                        value={selectedMission.XpReward || ''}
                                                                                        onChange={(e) => handleMissionChange(e, 'XpReward')}
                                                                                        className={`form-control ${!isEditingMission ? 'input-not-editable' : ''}`}
                                                                                        onWheel={(e) => e.target.blur()} // Prevent scrolling
                                                                                        disabled={!isEditingMission} // Disable input when not editing
                                                                                    />
                                                                                </div>
                                                                                <div className="edit-mission-field">
                                                                                    <label>Level</label>
                                                                                    <input
                                                                                        type="number"
                                                                                        value={selectedMission.level || ''}
                                                                                        onChange={(e) => handleMissionChange(e, 'level')}
                                                                                        className={`form-control ${!isEditingMission ? 'input-not-editable' : ''}`}
                                                                                        onWheel={(e) => e.target.blur()} // Prevent scrolling
                                                                                        disabled={!isEditingMission} // Disable input when not editing
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="right-column">
                                                                                <div className="edit-mission-field">
                                                                                    <label>Mission Type</label>
                                                                                    <input
                                                                                        type="text"
                                                                                        value={selectedMission.MissionType || ''}
                                                                                        onChange={(e) => handleMissionChange(e, 'MissionType')}
                                                                                        className={`form-control ${!isEditingMission ? 'input-not-editable' : ''}`}
                                                                                        disabled={!isEditingMission} // Disable input when not editing
                                                                                    />
                                                                                </div>
                                                                                <div className="edit-mission-field">
                                                                                    <label>Pref Name</label>
                                                                                    <input
                                                                                        type="text"
                                                                                        value={selectedMission.PrefName || ''}
                                                                                        onChange={(e) => handleMissionChange(e, 'PrefName')}
                                                                                        className={`form-control ${!isEditingMission ? 'input-not-editable' : ''}`}
                                                                                        disabled={!isEditingMission} // Disable input when not editing
                                                                                    />
                                                                                </div>
                                                                                <div className="edit-mission-field">
                                                                                    <label>Pref Goal Value</label>
                                                                                    <input
                                                                                        type="number"
                                                                                        value={selectedMission.PrefGoalValue || ''}
                                                                                        onChange={(e) => handleMissionChange(e, 'PrefGoalValue')}
                                                                                        className={`form-control ${!isEditingMission ? 'input-not-editable' : ''}`}
                                                                                        onWheel={(e) => e.target.blur()} // Prevent scrolling
                                                                                        disabled={!isEditingMission} // Disable input when not editing
                                                                                    />
                                                                                </div>
                                                                                <div className="checkbox-container">
                                                                                    <div className="checkbox-field">
                                                                                        <label>Specific Items</label>
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            checked={selectedMission.SpecificItems || false}
                                                                                            onChange={(e) => handleMissionChange(e, 'SpecificItems')}
                                                                                            disabled={!isEditingMission} // Disable checkbox when not editing
                                                                                        />
                                                                                    </div>
                                                                                    <div className="checkbox-field">
                                                                                        <label>Is New Mission</label>
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            checked={selectedMission.IsNewMission || false}
                                                                                            onChange={(e) => handleMissionChange(e, 'IsNewMission')}
                                                                                            disabled={!isEditingMission} // Disable checkbox when not editing
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {/* Item Names dropdown and buttons */}
                                                                        <div className="item-names-container">
                                                                            {selectedMission.ItemNames && selectedMission.ItemNames.length > 0 ? (
                                                                                selectedMission.ItemNames.map((item, index) => (
                                                                                    <div key={index} className="item-dropdown-container">
                                                                                        {index === 0 && <label>Item Names</label>}
                                                                                        <div className="mission-dropdown-wrapper">
                                                                                            <div className="mission-dropdown">
                                                                                                <ItemDropdown
                                                                                                    items={items}
                                                                                                    selectedItem={item}
                                                                                                    onItemSelect={(newItem) => handleItemNameChange(index, newItem)}
                                                                                                    disabled={!isEditingMission}
                                                                                                />
                                                                                            </div>
                                                                                            {isEditingMission && (
                                                                                                <button
                                                                                                    className="icon-button remove-field-button custom-removefield"
                                                                                                    onClick={() => handleRemoveItem(index)}
                                                                                                    data-tooltip="Fjern genstand"
                                                                                                >
                                                                                                    <i className="bi bi-x-circle"></i>
                                                                                                </button>
                                                                                            )}
                                                                                        </div>
                                                                                        {isEditingMission && (
                                                                                            <button
                                                                                                className="icon-button add-field-button custom-addfield"
                                                                                                onClick={handleAddItem}
                                                                                                data-tooltip="Tilføj genstand"
                                                                                            >
                                                                                                <i className="bi bi-plus-circle"></i>
                                                                                            </button>
                                                                                        )}
                                                                                    </div>

                                                                                ))
                                                                            ) : (
                                                                                <p>Ingen genstande valgt.</p>
                                                                            )}
                                                                             {isEditingMission && (!selectedMission.ItemNames || selectedMission.ItemNames.length === 0) && (
                                                                                <div classname="item-dropdown-container">
                                                                                <button
                                                                                    className="icon-button add-field-button custom-addfield"
                                                                                    onClick={handleAddItem}
                                                                                    data-tooltip="Tilføj genstand"
                                                                                >
                                                                                    <i className="bi bi-plus-circle"></i>
                                                                                </button>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        {/* Save and Cancel Buttons */}
                                                                        <div className="button-actions">
                                                                            {!isEditingMission ? (
                                                                                <button
                                                                                    className="icon-button edit-element-button"
                                                                                    onClick={() => setIsEditingMission(true)} // Enter edit mode
                                                                                    data-tooltip="Rediger mission"
                                                                                >
                                                                                    <i className="bi bi-pencil"></i>
                                                                                </button>
                                                                            ) : (
                                                                                <>
                                                                                    <button
                                                                                        className="icon-button save-element-button"
                                                                                        onClick={() => handleSaveMission(expandedChapter, selectedMission.missionId)}
                                                                                        data-tooltip="Gem mission"
                                                                                    >
                                                                                        <i className="bi bi-floppy"></i>
                                                                                    </button>
                                                                                    <button
                                                                                        className="icon-button"
                                                                                        onClick={handleCancel} // Use the handleCancel function
                                                                                        data-tooltip="Annuller"
                                                                                    >
                                                                                        <i className="bi bi-arrow-return-left"></i>
                                                                                    </button>
                                                                                </>
                                                                            )}
                                                                            {/* {isEditingMission && ( // bruges ikke da create og delete ikke er sat op i Unity
                                                                                <div className="remove-button-container">
                                                                                    <button
                                                                                        className="icon-button remove-element-button"
                                                                                        onClick={() => handleDeleteMission(expandedChapter, mission.missionId)}
                                                                                        data-tooltip="Fjern mission"
                                                                                    >
                                                                                        <i className="bi bi-trash3-fill"></i>
                                                                                    </button>
                                                                                </div>
                                                                            )} */}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </React.Fragment>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td>No missions available.</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                            {/* Render Quizzes */}
                            {showQuizzesFor === expandedChapter && (
                                <div className="element-data-container">
                                    <div className="search-container">
                                        <input
                                            type="text"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            placeholder="Søg i quizzer..."
                                            className="search-input"
                                        />
                                    </div>
                                    <p></p>
                                    <table className="element-table">
                                        <tbody>
                                            {quizzesData[expandedChapter] && quizzesData[expandedChapter].length > 0 ? (
                                                filterBySearchTerm(quizzesData[expandedChapter], searchTerm).map((quiz) => (
                                                    <React.Fragment key={quiz.quizId}>
                                                        {quiz.questions?.length > 0 ? (
                                                            quiz.questions.map((question) => (
                                                                <React.Fragment key={question.QuestionID}>
                                                                    <tr>
                                                                        <td>
                                                                            <button
                                                                                className={`full-width-button ${expandedQuiz === question.QuestionID ? 'expanded' : ''}`}
                                                                                onClick={() => handleQuizClick(question.QuestionID, quiz.quizId, expandedChapter)}
                                                                            >
                                                                                {question.QuestionID || 'Untitled Question'}
                                                                                <i className={`bi ${expandedQuiz === question.QuestionID ? 'bi-chevron-double-up' : 'bi-chevron-double-down'}`}></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                    {expandedQuiz === question.QuestionID && (
                                                                        <tr className="expanded-row expanded">
                                                                            <td>
                                                                                <div className="edit-question-data-container">
                                                                                    <div className="edit-question-field">
                                                                                        <label>Spørgsmålstekst</label>
                                                                                        <input
                                                                                            type="text"
                                                                                            value={isEditingQuiz ? (quizChanges[expandedChapter]?.[quiz.quizId]?.questions?.find(q => q.QuestionID === question.QuestionID)?.Text || question.Text) : question.Text}
                                                                                            onChange={(e) => isEditingQuiz && handleQuizChange(e, expandedChapter, quiz.quizId, question.QuestionID, 'Text')}
                                                                                            className={`form-control ${!isEditingQuiz ? 'input-not-editable' : ''}`}
                                                                                            disabled={!isEditingQuiz}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="answer-choices-container">
                                                                                        <div className="edit-question-field">
                                                                                            <label>Valgmuligheder</label>
                                                                                            <div>
                                                                                                {(quizChanges[expandedChapter]?.[quiz.quizId]?.questions?.find(q => q.QuestionID === question.QuestionID)?.Choices || question.Choices || []).map((choice, index) => (
                                                                                                    <div key={index} className="mb-2 d-flex align-items-center">
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            value={choice || ''}
                                                                                                            onChange={(e) => isEditingQuiz && handleChoiceChange(e, expandedChapter, quiz.quizId, question.QuestionID, index)}
                                                                                                            className={`form-control ${!isEditingQuiz ? 'input-not-editable' : ''}`}
                                                                                                            disabled={!isEditingQuiz}
                                                                                                        />
                                                                                                        {isEditingQuiz && (
                                                                                                            <button
                                                                                                                className="icon-button remove-field-button"
                                                                                                                onClick={() => handleRemoveChoice(expandedChapter, quiz.quizId, question.QuestionID, index)}
                                                                                                                data-tooltip="Fjern valgmulighed"
                                                                                                            >
                                                                                                                <i className="bi bi-x-circle"></i>
                                                                                                            </button>
                                                                                                        )}
                                                                                                    </div>
                                                                                                ))}
                                                                                                {isEditingQuiz && (
                                                                                                    <button
                                                                                                        className="icon-button add-field-button"
                                                                                                        onClick={() => handleAddChoice(expandedChapter, quiz.quizId, question.QuestionID)}
                                                                                                        data-tooltip="Tilføj valgmulighed"
                                                                                                    >
                                                                                                        <i className="bi bi-plus-circle"></i>
                                                                                                    </button>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="edit-question-field input-with-button">
                                                                                            <label>Svar</label>
                                                                                            <Form.Select
                                                                                                value={isEditingQuiz ? (quizChanges[expandedChapter]?.[quiz.quizId]?.questions?.find(q => q.QuestionID === question.QuestionID)?.Answer || question.Answer) : question.Answer}
                                                                                                onChange={(e) => isEditingQuiz && handleQuizChange(e, expandedChapter, quiz.quizId, question.QuestionID, 'Answer')}
                                                                                                disabled={!isEditingQuiz}
                                                                                            >
                                                                                                {(quizChanges[expandedChapter]?.[quiz.quizId]?.questions?.find(q => q.QuestionID === question.QuestionID)?.Choices || question.Choices || []).map((choice, index) => (
                                                                                                    <option key={index} value={choice}>
                                                                                                        {choice}
                                                                                                    </option>
                                                                                                ))}
                                                                                            </Form.Select>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="edit-question-field explanation">
                                                                                        <label>Forklaring</label>
                                                                                        <textarea
                                                                                            value={isEditingQuiz ? (quizChanges[expandedChapter]?.[quiz.quizId]?.questions?.find(q => q.QuestionID === question.QuestionID)?.ExplanationText || question.ExplanationText) : question.ExplanationText}
                                                                                            onChange={(e) => isEditingQuiz && handleQuizChange(e, expandedChapter, quiz.quizId, question.QuestionID, 'ExplanationText')}
                                                                                            className={`form-control ${!isEditingQuiz ? 'input-not-editable' : ''}`}
                                                                                            disabled={!isEditingQuiz}
                                                                                        />
                                                                                    </div>

                                                                                    <div className="button-actions">
                                                                                        {isEditingQuiz ? (
                                                                                            <>
                                                                                                <button
                                                                                                    className="icon-button save-element-button"
                                                                                                    onClick={() => handleSaveQuiz(expandedChapter, quiz.quizId)}
                                                                                                    data-tooltip="Gem spørgsmål"
                                                                                                >
                                                                                                    <i className="bi bi-floppy"></i>
                                                                                                </button>
                                                                                                <button
                                                                                                    className="icon-button"
                                                                                                    onClick={() => setIsEditingQuiz(false)} // Cancel edit
                                                                                                    data-tooltip="Annuller"
                                                                                                >
                                                                                                    <i className="bi bi-arrow-return-left"></i>
                                                                                                </button>
                                                                                            </>
                                                                                        ) : (
                                                                                            <button
                                                                                                className="icon-button edit-element-button"
                                                                                                onClick={() => setIsEditingQuiz(true)} // Start edit
                                                                                                data-tooltip="Rediger spørgsmål"
                                                                                            >
                                                                                                <i className="bi bi-pencil"></i>
                                                                                            </button>
                                                                                        )}
                                                                                    </div>
                                                                                </div>

                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                </React.Fragment>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td>Ingen spørgsmål tilgængelige.</td>
                                                            </tr>
                                                        )}
                                                    </React.Fragment>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td>Ingen quizzer tilgængelige.</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                    )}
                </>
            )}
        </div>
    );
}