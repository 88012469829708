import React, { useState, useEffect } from 'react';
import { doc, setDoc, collection, updateDoc, deleteDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../FirebaseConfig';

const AdminPage = () => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [role, setRole] = useState('');
    const [users, setUsers] = useState([]);
    const [editUserData, setEditUserData] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [isFormChanged, setIsFormChanged] = useState(false);
    const [initialEmail, setInitialEmail] = useState('');
    const [initialName, setInitialName] = useState('');
    const [initialRole, setInitialRole] = useState('');
    const [initialEditData, setInitialEditData] = useState({});
    const [isUserFormChanged, setIsUserFormChanged] = useState({});

    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, 'Users'), (snapshot) => {
            const usersData = snapshot.docs.map(doc => ({
                email: doc.id,
                ...doc.data()
            }));
            setUsers(usersData);

            // Initialize both initialEditData and editUserData with fetched users
            const initialData = {};
            const editData = {};
            usersData.forEach(user => {
                initialData[user.email] = {
                    email: user.email,
                    Name: user.Name,
                    Role: user.Role
                };
                editData[user.email] = {
                    email: user.email,
                    Name: user.Name,
                    Role: user.Role
                };
            });

            setInitialEditData(initialData);  // This stores the initial state
            setEditUserData(editData);        // This prepopulates the edit state with the same values
        }, (error) => {
            console.error('Error listening to users: ', error);
        });

        return () => unsubscribe();
    }, []);

    const handleAddUser = async () => {
        if (!validateEmail(email)) {
            alert('Indtast venligst en gyldig e-mailadresse.');
            return;
        }
        if (!validateName(name)) {
            alert('Navn kan ikke være tomt.');
            return;
        }
        if (!validateRole(role)) {
            alert('Vælg venligst en gyldig rolle (Administrator eller User).');
            return;
        }

        try {
            await setDoc(doc(db, 'Users', email.toLowerCase()), {
                Name: name,
                Role: role,
            });
            alert('Bruger blev tilføjet.');
            resetForm(); // Reset the form after successful addition
        } catch (error) {
            console.error('Error adding user: ', error);
            alert('Kunne ikke tilføje bruger');
        }
    };

    const handleDeleteUser = async (email) => {
        if (window.confirm(`Are you sure you want to delete the user ${email}?`)) {
            try {
                await deleteDoc(doc(db, 'Users', email));
                alert('Bruger slettet med succes.');
            } catch (error) {
                console.error('Error deleting user: ', error);
                alert('Kunne ikke slette bruger.');
            }
        }
    };

    const checkIfUserChanged = (email, updatedUserData) => {
        const initialUser = initialEditData[email];  // Get initial data
        const editedUser = updatedUserData[email];   // Get the latest edited data

        // If initial data isn't available, consider it changed
        if (!initialUser) return true;

        // Validate the new data
        const isEmailValid = validateEmail(editedUser.email ?? '');
        const isNameValid = validateName(editedUser.Name ?? '');
        const isRoleValid = validateRole(editedUser.Role ?? '');

        // Check if any field has changed and if the new values are valid
        const isChanged = (
            (editedUser.Name ?? '') !== (initialUser.Name ?? '') ||
            (editedUser.Role ?? '') !== (initialUser.Role ?? '') ||
            (editedUser.email ?? '') !== (initialUser.email ?? '')
        );

        return isChanged && isEmailValid && isNameValid && isRoleValid;
    };

    const handleEditChange = (email, field, value) => {
        const updatedUserData = {
            ...editUserData,
            [email]: {
                ...editUserData[email],
                [field]: value
            }
        };

        // Update the form's state with the latest changes
        setEditUserData(updatedUserData);

        // Check if any field has changed and if the new values are valid
        const isChangedAndValid = checkIfUserChanged(email, updatedUserData);

        // Update the change status for the specific user
        setIsUserFormChanged(prev => ({
            ...prev,
            [email]: isChangedAndValid
        }));
    };

    const handleUpdateUser = async (oldEmail) => {
        const editedData = editUserData[oldEmail];
        const user = users.find(u => u.email === oldEmail);

        if (editedData) {
            const newEmail = editedData.email?.toLowerCase() || oldEmail;

            if (!validateEmail(newEmail)) {
                alert('Indtast venligst en gyldig e-mailadresse.');
                return;
            }
            if (!validateName(editedData.Name)) {
                alert('Navn må ikke være tomt.');
                return;
            }
            if (!validateRole(editedData.Role)) {
                alert('Vælg venligst en gyldig rolle (Administrator eller Bruger).');
                return;
            }

            const updatedFields = {};
            if (editedData.Name !== undefined && editedData.Name !== user.Name) {
                updatedFields.Name = editedData.Name;
            }
            if (editedData.Role !== undefined && editedData.Role !== user.Role) {
                updatedFields.Role = editedData.Role;
            }

            try {
                if (newEmail !== oldEmail) {
                    await setDoc(doc(db, 'Users', newEmail), {
                        ...updatedFields,
                        Name: updatedFields.Name || user.Name,
                        Role: updatedFields.Role || user.Role,
                    });
                    await deleteDoc(doc(db, 'Users', oldEmail));
                    alert('Brugerens e-mail opdateret med succes.');
                } else {
                    if (Object.keys(updatedFields).length > 0) {
                        await updateDoc(doc(db, 'Users', oldEmail), updatedFields);
                    }
                    alert('Bruger opdateret med succes.');
                }

                // Reset form change detection for this user after a successful update
                setIsUserFormChanged(prev => ({ ...prev, [oldEmail]: false }));
            } catch (error) {
                console.error('Error updating user: ', error);
                alert('Kunne ikke opdatere bruger.');
            }
        }
    };

    const resetForm = () => {
        setEmail('');
        setName('');
        setRole('');
        setIsFormChanged(false);
    };

    const handleInputChange = (setter, field) => (event) => {
        const newValue = event.target.value;

        setter(newValue);

        const updatedEmail = field === 'email' ? newValue : email;
        const updatedName = field === 'name' ? newValue : name;
        const updatedRole = field === 'role' ? newValue : role;

        setIsFormChanged(checkIfFormChanged(updatedEmail, updatedName, updatedRole));
    };

    const checkIfFormChanged = (newEmail, newName, newRole) => {
        return (
            newEmail !== initialEmail ||
            newName !== initialName ||
            newRole !== initialRole
        );
    };

    const validateEmail = (email) => {
        // A simple email validation regex pattern
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validateName = (name) => {
        return (name ?? '').trim() !== '';
    };

    const validateRole = (role) => {
        return role === 'Admin' || role === 'User';
    };

    return (
        <div className="admin-page">
            <div className="add-user-container">
                <h3 className="admin-section-header">Tilføj Bruger</h3>
                <div className="input-fields">
                    <input
                        type="email"
                        placeholder="E-mail"
                        value={email}
                        onChange={handleInputChange(setEmail, 'email')}
                        className={`admin-input ${!validateEmail(email) && email !== '' ? 'input-error' : ''}`}
                    />
                    <input
                        type="text"
                        placeholder="Navn"
                        value={name}
                        onChange={handleInputChange(setName, 'name')}
                        className={`admin-input ${!validateName(name) && name !== '' ? 'input-error' : ''}`}
                    />
                    <select
                        name="Role"
                        id="Role"
                        value={role}
                        onChange={handleInputChange(setRole, 'role')}
                        className={`admin-input ${!validateRole(role) && role !== '' ? 'input-error' : ''}`}
                    >
                        <option value="">Vælg en rolle</option>
                        <option value="Admin">Administrator</option>
                        <option value="User">User</option>
                    </select>
                </div>
                <div className="button-actions">
                    <button
                        className={`icon-button save-element-button ${(!isFormChanged || !validateEmail(email) || !validateName(name) || !validateRole(role)) ? 'admin-button-disabled' : ''}`}
                        onClick={isFormChanged ? handleAddUser : null}
                        data-tooltip="Gem ændringer"
                        disabled={!isFormChanged || !validateEmail(email) || !validateName(name) || !validateRole(role)}
                    >
                        <i className="bi bi-floppy"></i>
                    </button>
                </div>
            </div>
            <div className="user-list-container">
                <br />
                <div className="search-container">
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Søg efter bruger"
                        className="search-input"
                    />
                </div>
                <h3 className="admin-section-header">Brugerliste</h3>
                <table className="admin-table">
                    <thead>
                        <tr>
                            <th>Email</th>
                            <th>Navn</th>
                            <th>Rolle</th>
                            <th className='actions-column'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {users
                            .filter(user => 
                                user.Name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                user.email.toLowerCase().includes(searchTerm.toLocaleLowerCase())
                            )
                            .map((user) => (
                                <tr key={user.email}>
                                    <td>
                                        <input
                                            type="text"
                                            value={editUserData[user.email]?.email ?? user.email}
                                            onChange={(e) =>
                                                handleEditChange(user.email, 'email', e.target.value)
                                            }
                                            className={`admin-input ${!validateEmail(editUserData[user.email]?.email) ? 'input-error' : ''}`}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={editUserData[user.email]?.Name ?? user.Name ?? ''}
                                            onChange={(e) =>
                                                handleEditChange(user.email, 'Name', e.target.value)
                                            }
                                            className={`admin-input ${!validateName(editUserData[user.email]?.Name ?? user.Name) ? 'input-error' : ''}`}
                                        />
                                    </td>

                                    <td>
                                        <select
                                            value={editUserData[user.email]?.Role ?? user.Role}
                                            onChange={(e) =>
                                                handleEditChange(user.email, 'Role', e.target.value)
                                            }
                                            className={`admin-input ${!validateRole(editUserData[user.email]?.Role) ? 'input-error' : ''}`}
                                        >
                                            <option value="">Select a role</option>
                                            <option value="Admin">Administrator</option>
                                            <option value="User">User</option>
                                        </select>
                                    </td>
                                    <td className="actions-column">
                                        <div className="admin-table-actions">
                                            <button
                                                className={`icon-button save-element-button ${!isUserFormChanged[user.email] ? 'admin-button-disabled' : ''}`}
                                                onClick={() => handleUpdateUser(user.email)}
                                                data-tooltip="Gem ændringer"
                                                disabled={!isUserFormChanged[user.email]}
                                            >
                                                <i className="bi bi-floppy"></i>
                                            </button>
                                            <button
                                                className="icon-button remove-element-button"
                                                onClick={() => handleDeleteUser(user.email)}
                                                data-tooltip="Fjern bruger"
                                            >
                                                <i className="bi bi-trash3-fill"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default AdminPage;
