import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import { doc, onSnapshot, setDoc } from 'firebase/firestore'; // Import setDoc to save data
import { db } from '../FirebaseConfig';
import { Form } from 'react-bootstrap';

export default function ItemDropdown({ onItemSelect, disabled, selectedItem }) { // Added selectedItem prop
    const [itemData, setItemData] = useState([]); // Initialize itemData as an array
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [localSelectedItem, setLocalSelectedItem] = useState(selectedItem || ''); // Use prop for initial state

    useEffect(() => {
        // Load data from Firestore
        const itemNamesRef = doc(db, 'ItemData', 'ItemNames');
        const itemPartsRef = doc(db, 'ItemData', 'ItemParts');

        // Set up listeners for ItemNames
        const unsubscribeNames = onSnapshot(itemNamesRef, (snapshot) => {
            const namesData = snapshot.data() || {};
            updateData(namesData);
        }, (error) => {
            console.error('Firestore error (ItemNames):', error);
        });

        // Set up listeners for ItemParts
        const unsubscribeParts = onSnapshot(itemPartsRef, (snapshot) => {
            const partsData = snapshot.data() || {};
            updateData(partsData);
        }, (error) => {
            console.error('Firestore error (ItemParts):', error);
        });

        // Cleanup listeners on unmount
        return () => {
            unsubscribeNames();
            unsubscribeParts();
        };
    }, []);

    const updateData = (data) => {
        // Extract keys (item names) and combine them
        const newItems = Object.keys(data);

        setItemData((prevData) => {
            const uniqueItems = new Set([...prevData, ...newItems]);
            return Array.from(uniqueItems); // Convert Set back to an array
        });

        setIsDataLoaded(true);
    };

    const handleChange = async (e, docId) => {
        const selectedItem = e.target.value;
        setLocalSelectedItem(selectedItem); // Update local selected item state

        // Notify parent component if onItemSelect is provided
        if (onItemSelect) {
            onItemSelect(selectedItem);
        }

        // Save the selected item to Firestore
        try {
            // Specify the document you want to update or create
            const userSelectionRef = doc(db, 'Chapters', docId, 'Missions');
            
            // Save the selected item in the document
            await setDoc(userSelectionRef, { selectedItem }, { merge: true });
            console.log('Selected item saved:', selectedItem);
        } catch (error) {
            console.error('Error saving selected item to Firestore:', error);
        }
    };

    useEffect(() => {
        setLocalSelectedItem(selectedItem); // Update local selected item when prop changes
    }, [selectedItem]);

    return (
        <div className="container mt-4">
            <div className="form-group">
                <Form.Select
                    id="itemDropdown"
                    className="form-control"
                    onChange={handleChange}
                    disabled={disabled || !isDataLoaded} // Disable dropdown if disabled prop is true or data is not loaded
                    value={localSelectedItem || ''} // Set the value of the select element to local state
                >
                    <option value="" disabled>
                        --Select an Item--
                    </option>
                    {isDataLoaded && itemData.sort().map((itemName) => (
                        <option key={itemName} value={itemName}>
                            {itemName}
                        </option>
                    ))}
                </Form.Select>
            </div>
        </div>
    );
}
