import React, { useState, useEffect } from 'react';
import { auth } from '../ContentManagerSystem/FirebaseConfig';
import { signOut } from 'firebase/auth';
import { getFirestore, doc, onSnapshot } from 'firebase/firestore'; // Import Firestore methods
import 'bootstrap/dist/css/bootstrap.min.css';
import DialogueDataTable from './CMSComponents/DialogueData';
import ChapterData from './CMSComponents/ChapterData';
import styles from './CMSComponents/css/CMS.module.css';
import InformationSign from './CMSComponents/InformationSign';
import MiniGame from './CMSComponents/MiniGame';
import CollectablesData from './CMSComponents/CollectablesData';
import AdminPage from './CMSComponents/AdminPage.js';
import StaticData from './CMSComponents/StaticData';
import CMSLogo from './CMSComponents/css/Images/CONTENTMANAGER.png';
import './CMSComponents/css/ComponentStyling.css';
import gslogo from '../Website/SiteComponents/images/LogoImages/GSlogoubg.png';

const ContentManager = ({ userRole }) => {
    const [activeComponent, setActiveComponent] = useState(null);
    const [selectedNavItem, setSelectedNavItem] = useState('');
    const [userDisplayName, setUserDisplayName] = useState('');
    const [userEmail, setUserEmail] = useState('');

    const db = getFirestore(); // Initialize Firestore

    useEffect(() => {
        const user = auth.currentUser;
        if (user) {
            setUserDisplayName(user.displayName || '');
            setUserEmail(user.email || '');

            // Set up Firestore listener
            const userDocRef = doc(db, 'Users', user.email);
            const unsubscribe = onSnapshot(userDocRef, (doc) => {
                if (!doc.exists()) {
                    // If the document doesn't exist, sign the user out
                    HandleSignOut();
                }
            });

            // Clean up the listener on component unmount
            return () => unsubscribe();
        }
    }, [db]);

    const HandleSignOut = () => {
        signOut(auth)
            .then(() => {
                console.log("Sign-out successful.");
            })
            .catch((error) => {
                console.error("An error happened during sign-out:", error);
            });
    };

    const handleNavClick = (item) => {
        setActiveComponent(item);
        setSelectedNavItem(item);
    };

    const renderActiveComponent = () => {
        switch (activeComponent) {
            case 'ChapterData':
                return <ChapterData />;
            case 'DialogueDataTable':
                return <DialogueDataTable />;
            case 'InformationSign':
                return <InformationSign />;
            case 'MiniGame':
                return <MiniGame />;
            case 'Collectables':
                return <CollectablesData />;
            case 'StaticData':
                return <StaticData />;
            case 'AdminPage':
                return userRole === 'Admin' ? <AdminPage /> : <div>Access Denied</div>;
            default:
                return <div className='container'><img src={CMSLogo} alt='CMS LOGO' className='CMS-Logo' /></div>;
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.topbar}>
                <img
                    src={gslogo}
                    alt="Logo"
                    className={styles.logo}
                    onClick={() => {
                        setActiveComponent(null);
                        setSelectedNavItem('');
                    }}
                />
                <div className="account-container">
                    {userDisplayName || userEmail ? (
                        <span style={{ marginRight: '10px' }}>
                            Logget ind som: <strong>{userDisplayName || userEmail}</strong>
                        </span>
                    ) : (
                        'Not logged in'
                    )}
                    <button className="logout-button" onClick={HandleSignOut}>
                        <i className="bi bi-box-arrow-right" style={{ color: 'red', fontSize: '25px' }}></i>
                        Log ud
                    </button>
                </div>
            </div>

            <div className={styles.sidebar}>
                <ul className={styles.navList}>
                    <li
                        className={selectedNavItem === 'ChapterData' ? styles.selected : ''}
                        onClick={() => handleNavClick('ChapterData')}
                    >
                        Kapitler
                    </li>
                    <li
                        className={selectedNavItem === 'DialogueDataTable' ? styles.selected : ''}
                        onClick={() => handleNavClick('DialogueDataTable')}
                    >
                        NPC Dialoger
                    </li>
                    <li
                        className={selectedNavItem === 'InformationSign' ? styles.selected : ''}
                        onClick={() => handleNavClick('InformationSign')}
                    >
                        Informationsskilte
                    </li>
                    <li
                        className={selectedNavItem === 'MiniGame' ? styles.selected : ''}
                        onClick={() => handleNavClick('MiniGame')}
                    >
                        Minigame
                    </li>
                    <li
                        className={selectedNavItem === 'Collectables' ? styles.selected : ''}
                        onClick={() => handleNavClick('Collectables')}
                    >
                        Samlinger
                    </li>
                    <li
                        className={selectedNavItem === 'StaticData' ? styles.selected : ''}
                        onClick={() => handleNavClick('StaticData')}
                    >
                        Statisk data
                    </li>
                    {userRole === 'Admin' && (
                        <li className={selectedNavItem === 'AdminPage' ? styles.selected : ''} onClick={() => handleNavClick('AdminPage')}>
                            Administrator
                        </li>
                    )}
                </ul>
            </div>

            <div className={styles.mainContent}>
                {renderActiveComponent()}
            </div>
        </div>
    );
};

export default ContentManager;
