import { doc, updateDoc} from "firebase/firestore";
import { db } from "../../FirebaseConfig";

export async function setGlobalUpdated() {
    // Create an update payload to update the global lastupdate timestmap in firestore.
    const updatedGlobalTimeStamp = {
        'LastUpdated': new Date()
    };
    // Update Global LastUpdated TimeStamp
    const docGlobalLastUpdated = doc(db, 'GlobalTimeStamp', 'ServerTimeStamp');
    await updateDoc(docGlobalLastUpdated, updatedGlobalTimeStamp);
};