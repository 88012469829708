import { collection, getDocs, updateDoc, doc, setDoc, getDoc, deleteDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { db } from '../FirebaseConfig'; // Import the Firestore instance
import '../CMSComponents/css/ComponentStyling.css';
import { setGlobalUpdated } from './Functions/GlobalUpdated';

const MiniGameDisplay = () => {
  const [categories, setCategories] = useState([]);
  const [items, setItems] = useState([]);
  const [activeTab, setActiveTab] = useState('Items');
  const [showPrompt, setShowPrompt] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [editData, setEditData] = useState({});
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isItemModalOpen, setIsItemModalOpen] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isAdding, setIsAdding] = useState(false); // Track if adding new entry

  useEffect(() => {
    // Load data from localStorage with error handling
    try {
      const localDataCategories = localStorage.getItem('categoriesData');
      const localDataItems = localStorage.getItem('itemsData');
      if (localDataCategories || localDataItems) {
        setCategories(JSON.parse(localDataCategories));
        setItems(JSON.parse(localDataItems));
        setIsDataLoaded(true);
      }
    } catch (error) {
      console.error('Error loading data from localStorage:', error);
    }

    const fetchData = async () => {
      try {
        const categoriesCollection = collection(db, 'MiniGame/Categories/MiniGameCategoriesData');
        const categoriesSnapshot = await getDocs(categoriesCollection);
        const categoriesData = categoriesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const itemsCollection = collection(db, 'MiniGame/Items/MiniGameItemsData');
        const itemsSnapshot = await getDocs(itemsCollection);
        const itemsData = itemsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Update state and localStorage
        try {
          setCategories(categoriesData);
          setItems(itemsData);
          localStorage.setItem('categoriesData', JSON.stringify(categoriesData));
          localStorage.setItem('itemsData', JSON.stringify(itemsData));
          setIsDataLoaded(true);
        } catch (error) {
          console.error('Error updating localStorage:', error);
        }
      } catch (error) {
        console.error('Error fetching data from Firestore:', error);
      }
    };

    fetchData();
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setShowPrompt(false);
  };

  const handleEditClick = (item) => {
    setEditData({
      ...item,
      Category: categories.find(cat => cat.Name === item.Category)?.id || '' // Set category based on item's current category
    });
    setIsAdding(false); // Ensure we're not in add mode
    if (activeTab === 'Categories') {
      setIsModalOpen(true);
    } else {
      setIsItemModalOpen(true);
    }
  };


  // {if added is fixed to unity}
  // const handleAddClick = () => {
  //   setEditData({}); // Clear form for adding a new entry
  //   setIsAdding(true); // Set add mode
  //   if (activeTab === 'Categories') {
  //     setIsModalOpen(true);
  //   } else {
  //     setIsItemModalOpen(true);
  //   }
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(',')[1];
        setEditData((prevData) => ({
          ...prevData,
          Sprite: base64String,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSaveClick = async () => {
    try {
      const collectionPath = activeTab === 'Categories' ? 'MiniGame/Categories/MiniGameCategoriesData' : 'MiniGame/Items/MiniGameItemsData';

      const docId = editData.Name.trim();  // Use 'Name' field as the document ID

      // Create a new object without the 'id' field for saving to Firestore
      const { id, ...dataToSave } = {
        ...editData,
        LastUpdated: new Date(), // Add the 'LastUpdated' field with current date
      };

      if (activeTab === 'Items') {
        const category = categories.find(cat => cat.id === editData.Category);
        if (category) {
          dataToSave.Category = category.Name; // Set the 'Category' field with the name
        } else {
          delete dataToSave.Category; // Ensure no 'Category' is set if no category found
        }
      } else {
        // If saving a category, remove the Category field if it exists
        delete dataToSave.Category; // Prevent adding a 'Category' field for categories
      }

      // Check for required fields
      if (!dataToSave.Name) {
        alert('Name is required.');
        return; // Prevent further execution
      }

      const docRef = doc(db, collectionPath, docId);
      const docSnap = await getDoc(docRef);

      if (isAdding) {
        const docRef = doc(db, collectionPath, docId);
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
          // Create a new document using setDoc and the provided Name as the ID
          await setDoc(docRef, dataToSave);
          const newEntry = { id: docId, ...dataToSave };

          if (activeTab === 'Categories') {
            setCategories((prev) => [...prev, newEntry]);
          } else if (activeTab === 'Items') {
            setItems((prev) => [...prev, newEntry]);
          }
        } else {
          alert(`Der findes allerede en post med navnet "${docId}". Vælg venligst et andet navn.`);
        }
      } else {
        // Update an existing document
        const docRef = doc(db, collectionPath, editData.id);
        await updateDoc(docRef, dataToSave);

        if (activeTab === 'Categories') {
          setCategories((prev) =>
            prev.map((cat) => (cat.id === editData.id ? { ...dataToSave, id: cat.id } : cat))
          );
        } else if (activeTab === 'Items') {
          setItems((prev) =>
            prev.map((itm) => (itm.id === editData.id ? { ...dataToSave, id: itm.id } : itm))
          );
        }
      }

      // Reset modal and form
      setEditMode(false);
      setEditData({});
      setIsModalOpen(false);
      setIsItemModalOpen(false);
      setIsAdding(false); // Reset add mode

      // Update global last updated timestamp
      await setGlobalUpdated();
    } catch (error) {
      console.error('Error saving document:', error);
    }
  };


  const handleCancelClick = () => {
    setEditMode(false);
    setEditData({});
    setIsModalOpen(false);
    setIsItemModalOpen(false);
    setIsAdding(false); // Reset add mode
  };

  const handleDeleteClick = async () => {
    setShowConfirmation(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const collectionPath = activeTab === 'Categories' ? 'MiniGame/Categories/MiniGameCategoriesData' : 'MiniGame/Items/MiniGameItemsData';
      const docRef = doc(db, collectionPath, editData.id);

      await deleteDoc(docRef);

      if (activeTab === 'Categories') {
        setCategories((prev) => prev.filter((cat) => cat.id !== editData.id));
      } else if (activeTab === 'Items') {
        setItems((prev) => prev.filter((itm) => itm.id !== editData.id));
      }

      setEditMode(false);
      setEditData({});
      setIsModalOpen(false);
      setIsItemModalOpen(false);

      // Calls Custom function to set global last updated
      await setGlobalUpdated();
    } catch (error) {
      console.error('Error deleting document:', error);
    } finally {
      setShowConfirmation(false);
    }
  };

  const handleCancelDelete = () => {
    setShowConfirmation(false);
  };

  const getDanishName = (tab) => {
    switch (tab) {
      case 'Categories':
        return 'Tilføj ny kategori';
      case 'Items':
        return 'Tilføj ny genstand';
      default:
        return '';
    }
  };

  return (
    <div className="element-data-container">
      <div className="category-button-container">
        <div className="category-button-grid">
          <button
            className={`category-button ${activeTab === 'Items' ? 'active' : ''}`}
            onClick={() => handleTabClick('Items')}
          >
            Genstande
          </button>
          <button
            className={`category-button ${activeTab === 'Categories' ? 'active' : ''}`}
            onClick={() => handleTabClick('Categories')}
          >
            Kategorier
          </button>

        </div>
      </div>

      {/* Centered Add Button for adding if its gets fixed to unity*/}
      {/* {activeTab && (
        <div className="add-object-button-container">
          <button className="add-object-button" onClick={handleAddClick}>
          <i className="bi bi-plus-circle add-icon"></i>
            {getDanishName(activeTab)}
          </button>
        </div>
      )} */}

      {activeTab && (
        <div className="gridContainer">
          {(activeTab === 'Categories' ? categories : items).map((item) => (
            <div
              className={`palette ${activeTab === 'Items' ? 'item-image' : 'category-image'}`}
              key={item.id}
              onClick={() => handleEditClick(item)}
            >
              <img
                className="minigameimg"
                src={`data:image/png;base64,${item.Sprite}`}
                alt={item.Name}
              />
              <div className="overlay">
                <div className="overlay-title">{item.DanishName}</div>
              </div>
            </div>
          ))}
        </div>
      )}
      {/* Modal for Categories */}
      {isModalOpen && (
        <div className="minigame-overlay" onClick={() => setIsModalOpen(false)}>
          <div className="minigame-modal-content" onClick={e => e.stopPropagation()}>
            <div className="minigame-modal-header">
              <h3>{isAdding ? 'Add New Category' : 'Rediger kategori'}</h3>
              <button
                className="icon-button"
                onClick={handleCancelClick}
                data-tooltip="Close"
              >
                <i className="bi bi-x-lg"></i>
              </button>
            </div>
            <div className="minigame-modal-body">
              <label className="minigame-modal-label" htmlFor="category-name">Navn</label>
              <input
                id="category-name"
                type="text"
                name="Name"
                value={editData.Name || ''}
                onChange={handleInputChange}
                className="minigame-modal-input"
                placeholder="Name"
              />
              <label className="minigame-modal-label" htmlFor="category-image">Billede</label>
              <input
                id="category-image"
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                className="minigame-modal-input"
              />
            </div>
            <div className="minigame-modal-footer">
              <button
                className="icon-button save-element-button"
                onClick={handleSaveClick}
                data-tooltip={isAdding ? 'Add Category' : 'Gem ændringer'}
              >
                <i className="bi bi-floppy"></i>
              </button>
              {/* {!isAdding && (
                <button
                  className="icon-button remove-element-button"
                  onClick={handleDeleteClick}
                  data-tooltip="Slet kategori"
                >
                  <i className="bi bi-trash3-fill"></i>
                </button>
              )} */}
            </div>
          </div>
        </div>
      )}

      {/* Modal for Items */}
      {isItemModalOpen && (
        <div className="minigame-overlay" onClick={() => setIsItemModalOpen(false)}>
          <div className="minigame-modal-content" onClick={e => e.stopPropagation()}>
            <div className="minigame-modal-header">
              <h3>{isAdding ? 'Add New Item' : 'Rediger genstand'}</h3>
              <button
                className="icon-button"
                onClick={handleCancelClick}
                data-tooltip="Close"
              >
                <i className="bi bi-x-lg"></i>
              </button>
            </div>
            <div className="minigame-modal-body">
              <label className="minigame-modal-label" htmlFor="item-category">Kategori</label>
              {activeTab === 'Items' && (
                <select
                  id="item-category"
                  name="Category"
                  value={editData.Category || ''}
                  onChange={handleInputChange}
                  className="minigame-modal-input"
                >
                  {categories.map((cat) => (
                    <option key={cat.id} value={cat.id}>{cat.Name}</option>
                  ))}
                </select>
              )}
              <label className="minigame-modal-label" htmlFor="item-name">Navn</label>
              <input
                id="item-name"
                type="text"
                name="Name"
                value={editData.Name || ''}
                onChange={handleInputChange}
                className="minigame-modal-input"
                placeholder="Name"
              />
              {activeTab === 'Items' && (
                <>
                  <label className="minigame-modal-label" htmlFor="item-danish-name">Dansk navn</label>
                  <input
                    id="item-danish-name"
                    type="text"
                    name="DanishName"
                    value={editData.DanishName || ''}
                    onChange={handleInputChange}
                    className="minigame-modal-input"
                    placeholder="Danish Name"
                  />
                </>
              )}
              <label className="minigame-modal-label" htmlFor="item-image">Billede</label>
              <input
                id="item-image"
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                className="minigame-modal-input"
              />
            </div>
            <div className="minigame-modal-footer">
              <button
                className="icon-button save-element-button"
                onClick={handleSaveClick}
                data-tooltip={isAdding ? 'Add Item' : 'Gem ændringer'}
              >
                <i className="bi bi-floppy"></i>
              </button>
              {/* {!isAdding && (
                <button
                  className="icon-button remove-element-button"
                  onClick={handleDeleteClick}
                  data-tooltip="Slet genstand"
                >
                  <i className="bi bi-trash3-fill"></i>
                </button>
              )} */}
            </div>
          </div>
        </div>
      )}

      {/* Confirmation Dialog */}
      {showConfirmation && (
        <div className="minigame-confirmation-overlay">
          <div className="minigame-confirmation-modal-content">
            <p>Warning! Are you sure you want to delete this {activeTab === 'Categories' ? 'category' : 'item'}?</p>
            <div className="minigame-confirmation-buttons">
              <button className="minigame-confirm-button" onClick={handleConfirmDelete}>
                <i className="bi bi-check"></i> Confirm
              </button>
              <button className="minigame-cancel-button" onClick={handleCancelDelete}>
                <i className="bi bi-x"></i> Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MiniGameDisplay;
