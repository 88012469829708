import React, { useState } from 'react';
import { signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth, provider, db } from '../ContentManagerSystem/FirebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import '../ContentManagerSystem/CMSComponents/css/ComponentStyling.css';
import CMSLogo from '../ContentManagerSystem/CMSComponents/css/Images/CONTENTMANAGER.png';

const Login = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    // Ensure the provider allows account selection
    provider.setCustomParameters({
        prompt: 'select_account', // This will force the Google account chooser to show
    });

    const handleLogin = async () => {
        setLoading(true);
        try {
            // Sign in with Google
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
            const email = user.email;

            // Check if the user exists in the Firestore 'Users' collection
            const userDocRef = doc(db, "Users", email.toLowerCase());
            const userDoc = await getDoc(userDocRef);

            if (!userDoc.exists()) {
                // User is not allowed, sign them out and show an alert
                await auth.signOut();
                alert("Du har ikke adgang til denne applikation.");
                // After signing out, navigate back to the login page
                navigate('/login');  // Make sure '/login' is the route for your Login component
            } else {
                // User is allowed, navigate to the CMS page
                navigate('/CMS');
            }
        } catch (error) {
            // Handle case when popup is closed without logging in
            if (error.code === 'auth/popup-closed-by-user') {
                console.log("Popup closed by user, resetting state.");
            } else {
                console.error("Fejl ved login:", error.message);
                alert(`Login fejlede: ${error.message}`);
            }
        } finally {
            // Reset loading state after a small timeout to ensure it resets
            setTimeout(() => setLoading(false), 200); // Reset after 200 ms
        }
    };

    return (
        <div className="login-background">
            <div className="login-container">
                <img src={CMSLogo} alt="CMS Logo" className="cms-logo-login" />
                <h2>Velkommen til Gebrugsspillets CMS</h2>
                <br />
                <h3>Log ind med Google</h3>
                <br />
                {loading ? (
                    <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                    <button className="login-button" onClick={handleLogin}>
                        <i className="bi bi-box-arrow-in-right login-icon"></i>
                        Log ind
                    </button>
                )}
            </div>
        </div>
    );
};

export default Login;
