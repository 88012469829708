import image1 from '../images/CollectablesImages/lifecycleoffurniture 1.png'; // Lifecycleoffurniture
import image2 from '../images/CollectablesImages/paper.png'; // Paper
import image3 from '../images/CollectablesImages/woodatrecyclecenter.png'; // Woodatrecyclecenter
import image4 from '../images/CollectablesImages/battery.png'; // Battery
import image5 from '../images/CollectablesImages/metalsorting.png'; // Metalsorting
import image6 from '../images/CollectablesImages/nearinfrared.png'; // Nearinfrared
import image7 from '../images/CollectablesImages/coffeemushrooms.png'; // Coffeemushrooms
import image8 from '../images/CollectablesImages/deo.png'; // Deo
import image9 from '../images/CollectablesImages/rubbercrusher 1.png'; // Rubbercrusher
import image10 from '../images/CollectablesImages/breakwashmelt.png'; // Breakwashmelt
import image11 from '../images/CollectablesImages/confused.png'; // Confused
import image12 from '../images/CollectablesImages/windoworregularglass 1.png'; // Windoworregularglass
import image13 from '../images/CollectablesImages/42plastic.png'; // Plastic
import image14 from '../images/CollectablesImages/pant.png'; // Pant
import image15 from '../images/CollectablesImages/plasticfacotry.png'; // Plasticfacotry
import image16 from '../images/CollectablesImages/clothessorting 1.png'; // Clothessorting
import image17 from '../images/CollectablesImages/lawfultextile 1.png'; // Lawfultextile
import image18 from '../images/CollectablesImages/pizzaboxthrowout.png'; // Pizzaboxthrowout

export const collectableImages = [
  { Name: 'WoodFlyer1', src: image1 },
  { Name: 'WoodFlyer2', src: image2 },
  { Name: 'WoodFlyer3', src: image3 },

  { Name: 'MetalFlyer1', src: image4 },
  { Name: 'MetalFlyer2', src: image5 },
  { Name: 'MetalFlyer3', src: image6 },

  { Name: 'RubberFlyer1', src: image7 },
  { Name: 'RubberFlyer2', src: image8 },
  { Name: 'RubberFlyer3', src: image9 },

  { Name: 'GlassFlyer1', src: image10 },
  { Name: 'GlassFlyer2', src: image11 },
  { Name: 'GlassFlyer3', src: image12 },

  { Name: 'PlasticFlyer1', src: image13 },
  { Name: 'PlasticFlyer2', src: image14 },
  { Name: 'PlasticFlyer3', src: image15 },

  { Name: 'TextileFlyer1', src: image16 },
  { Name: 'TextileFlyer2', src: image17 },
  { Name: 'TextileFlyer3', src: image18 },
]; 